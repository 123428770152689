import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { messages } from '../../../../messages'
import { LinearProgress, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/system'
import { yupResolver } from '@hookform/resolvers/yup'
import { joinOrCreateLeagueSchema } from '../../../../validations/joinOrCreateLeague'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createOrJoinLeague,
  generateLeaguePassword,
  getLeagueInfoByPassword,
} from '../../../../api/services/leagues'
import CustomizedSnackbar from '../../../../components/common/CustomizedSnackbar'
import { pushUserLeagueAndManager } from '../../../../store/slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useEffect } from 'react'
import { setManager } from '../../../../store/slices/managerSlice'
import { setLeague } from '../../../../store/slices/leagueSlice'
import LeagueOrMockToggleButton from './LeagueOrMockToggleButton'
import { useState, forwardRef } from 'react'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function LeagueFormDialog({ isMock, setIsMock, leaguesRefetch, closeMenu }) {
  const { leagues } = useSelector((state) => state.user)
  const [open, setOpen] = useState(leagues.length < 1 ? true : false)
  const [type, setType] = useState('create')
  const [managerName, setManagerName] = useState('')
  const [inputError, setInputError] = useState('')

  const dispatch = useDispatch()

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(joinOrCreateLeagueSchema),
  })

  const formValues = watch()

  //   Only generate a password if user is creating a league
  const { data: leagueData } = useQuery(
    ['generate-password'],
    () => generateLeaguePassword(),
    {
      enabled: open && type === 'create',
      onSuccess: (data) => {
        if (isMock) {
          setValue('name', data.data.password.toUpperCase())
        }
      }
    }
  )

  useQuery(
    ['join_league'],
    () => getLeagueInfoByPassword(formValues.password),
    {
      enabled: open && formValues.password?.trim().length > 0  && type === 'join',
      onSuccess: (data) => {
        setIsMock(data.is_mock)
        if (data.is_mock) {
          setValue('name', data.password.toUpperCase())
        } else {
          setValue('name', data.name)
        }
      }
    }
  )

  useEffect(() => {
    if (isMock) {
      setValue('is_mock', true)
    }
  }, [isMock])

  useEffect(() => {

    if (type === 'create' && leagueData) {
      const generatedPassword = leagueData.data.password
      setValue('password', generatedPassword)
    }
  }, [leagueData])

  useEffect(() => {

    const inviteCode = localStorage.getItem('invite_code')
    if (inviteCode) {
      setOpen(true)
      setType('join')
      setValue('password', inviteCode)
      setValue('name', inviteCode.toUpperCase())
      localStorage.removeItem('invite_code')
    }
  }, [])

  // Create or join league
  const { mutateAsync, isLoading, isError, error, isSuccess } = useMutation(
    () => createOrJoinLeague({ ...getValues(), is_mock: isMock }, type)
  )

  // After league's create/join: Close dialog, update redux and show "copy to clipboard" message.
  const leaguesUpdateHandler = (res) => {
    const leagueData = res.data.league
    openToggle()
    dispatch(pushUserLeagueAndManager(res.data))
    dispatch(setManager(res.data.manager))
    dispatch(setLeague(leagueData))
    leaguesRefetch()
    reset()
  }

  const openToggle = () => {
    setOpen((prev) => !prev)
    setType('create')
    closeMenu()
  }

  const onSubmit = () => {

    mutateAsync().then((res) => {
      leaguesRefetch()
      leaguesUpdateHandler(res)
    })
  }

  const getTitle = () => {
    return type == 'create' ? (isMock ? messages[2].StartNewMockDraft : messages[2].JoinAnyLeagueMessage) :
      (isMock ? `${messages[2].JoinMockDraft}: ${formValues.name}` : `${messages[2].JoinLeague}: ${formValues.name}`)
  }

  // Manager Name counter
  const handleManagerNameChange = (event) => {
    const newValue = event.target.value
    setManagerName(newValue)
    setInputError(newValue.length > 12 ? 'Must be at most 12 characters' : '')
  }

  return (
    <>
      <Box
        id='createOrJoinLeagueBtn'
        sx={{ whiteSpace: 'nowrap', fontWeight: 'bold', color: 'gray' }}
        onClick={openToggle}
      >
        Create league or mock draft +
      </Box>
      <CustomizedSnackbar
        isError={isError}
        isSuccess={isSuccess}
        errorMessage={error?.response.data.message}
        successMessage={
          type === 'create'
            ? messages[2].createdLeagueMessage
            : messages[2].joinLeagueMessage
        }
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={openToggle}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
      >
        <DialogTitle textAlign="left">
          <Box display="flex" justifyContent="space-between">
            {getTitle()}
            <HighlightOffIcon sx={{ cursor: 'pointer' }} onClick={openToggle} />
          </Box>
        </DialogTitle>
        <DialogContent>
          {
            type === 'create' &&
            <LeagueOrMockToggleButton isMock={isMock} setIsMock={(v) => {
              setIsMock(v)
              if (v) {
                setValue('name', formValues.password.toUpperCase())
              } else {
                setValue('name', '')
              }
            }} />
          }

          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            {type === 'create' &&
              <TextField
                {...register('name')}
                InputLabelProps={{
                  shrink: formValues.name,
                  disableAnimation: !formValues.name
                }}
                label="League Name"
                disabled={isMock}
                sx={{ margin: '15px 0' }}
                error={!!errors.name}
                helperText={errors.name?.message}
                fullWidth
              />
            }
            <TextField
              {...register('manager_name')}
              label="Your Team's Name"
              sx={type === 'create' ? {} : { marginTop: '10px' }}
              value={managerName}
              onChange={handleManagerNameChange}
              helperText={inputError}
              error={!!inputError}
              InputProps={{
                endAdornment:
                  <Box component="span" sx={{ fontSize: '0.75rem', color: 'gray' }}>
                    {`${managerName.length}/12`}
                  </Box>
              }}
              fullWidth
            />
            {isLoading ? (
              <LinearProgress sx={{ marginTop: '25px' }} />
            ) : (
              <Button
                id="confirmLeagueBtn"
                variant="outlined"
                sx={{ marginTop: '25px' }}
                type="submit"
                fullWidth
                disabled={!!inputError}
              >
                Confirm
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

import { Box } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { Text } from '../../../styles/global'
import Navbar from '../../Navbar'

export const NoItemContainer = ({ text, hideNavbar }) => {
  return (
    <>
      {!hideNavbar && <Navbar />}

      <Container>
        <Box margin="20px" textAlign="center">
          <Box component="img" src="/assets/images/logo-bw.png" width="100%" />
          <Text variant="h2" color="#d9d9d9" fontWeight="bold" maintainsize>
            {text}
          </Text>
        </Box>
      </Container>
    </>
  )
}

import { axiosClient } from '../axios'

export const getMyTeam = async (managerId) => {
  var myTeam = await axiosClient.get(`/leagues/managers/${managerId}/my-team`)
  return myTeam.data
}

export const getTeamSchedule = async (teamId) => {
  var schedule = await axiosClient.get(`/leagues/teams/${teamId}/schedule`)
  return schedule.data
}

export const getAvailableTeams = async (managerId) => {
  var availableTeams = await axiosClient.get(
    `/leagues/managers/${managerId}/available-teams`
  )
  return availableTeams
}

export const getAvailableOffenses = async (managerId) => {
  var availableOffenses = await axiosClient.get(
    `/leagues/managers/${managerId}/available-offenses`
  )
  return availableOffenses.data
}

export const getAvailableDefenses = async (managerId) => {
  var availableDefenses = await axiosClient.get(
    `/leagues/managers/${managerId}/available-defenses`
  )
  return availableDefenses.data
}

import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
  Slide,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'

import {
  deleteManager,
  getAllLeagues,
} from '../../../../../api/services/leagues'
import CustomizedSnackbar from '../../../../../components/common/CustomizedSnackbar'

import DeleteIcon from '@mui/icons-material/Delete'
import {
  updateUserLeagues,
} from '../../../../../store/slices/userSlice'
import CancelIcon from '@mui/icons-material/CancelOutlined'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function DeleteManagerDialog({ manager, refetchLeague }) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)

  const { refetch: refetchAllLeagues } = useQuery(
    ['all-leagues'],
    () => getAllLeagues(),
    {
      retry: false,
      enabled: false,
      onSuccess: (updatedLeaguesData) => {
        dispatch(updateUserLeagues(updatedLeaguesData))
      },
    }
  )

  const {
    mutate: mutateDelete,
    isLoading: isLoadingDelete,
    error: errorDelete,
    isError: isErrorDelete,
    isSuccess: isSuccessDelete,
  } = useMutation(['delete-league'], () => deleteManager(manager.id), {
    onSuccess: () => {
      setTimeout(() => {
        refetchLeague()
        refetchAllLeagues()
        setOpen(false)
      }, 1000)
    },
  })

  return (
    <div>
      <div
        onClick={() => setOpen(true)}
        style={{
          cursor: 'pointer'
        }}
        className="">
        <CancelIcon
          sx={{
            width: '15px',
            color: 'error.main',
            marginLeft: '5px',
          }}
        />
      </div>

      <CustomizedSnackbar
        isError={isErrorDelete}
        isSuccess={isSuccessDelete}
        errorMessage={
          errorDelete?.response.status === 403
            ? errorDelete.response.data.message
            : null
        }
        successMessage={'Manager successfully deleted'}
      />
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Are you sure you would like to remove {manager.name} from the league?</DialogTitle>

        {(isLoadingDelete) ? (
          <LinearProgress />
        ) : (
          !isSuccessDelete &&
          <DialogActions>
            <Button
              variant="outlined"
              startIcon={<CancelIcon />}
              onClick={() => setOpen(false)}
              sx={{ display: 'flex', alignItems: 'center', paddingX: 1, paddingY: 0.5 }}
            >
              <span style={{
                marginTop: 4
              }}
              >
                Cancel
              </span>
            </Button>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              color="error"
              id={`deleteManagerBtn_${manager.id}`}
              onClick={mutateDelete}
              sx={{ display: 'flex', alignItems: 'center', paddingX: 1, paddingY: 0.5 }}
            >
              <span style={{
                marginTop: 4
              }}
              >
                Submit
              </span>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}

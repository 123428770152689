import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  league_id: 0,
  claimant_id: 0,
  team_to_drop_id: null,
  owner_id: null,
  team_to_steal_id: 0,
  bid: 0,
  delayed: false,
  replace: false,
  type: null
}

export const stealSlice = createSlice({
  name: 'steal',
  initialState: initialState,
  reducers: {
    setStealData: (state, action) => {
      state.league_id = action.payload.league_id
      state.claimant_id = action.payload.id
    },
    setStealTeamDropId: (state, action) => {
      if (action.payload) {
        state.team_to_drop_id = action.payload
      }
    },
    setStealBid: (state, action) => {
      state.bid = action.payload
    },
    setStealTransfer: (state, action) => {
      state.team_to_steal_id = action.payload.teamToTransferId
      state.owner_id = action.payload.ownerId
    },
    setStealDelayedFlag: (state) => {
      state.delayed = true
    },
    setStealReplaceFlag: (state) => {
      state.replace = true
    },
    clearFlags: (state) => {
      state.delayed = false
      state.delayed = false
    },
    resetSteal: () => initialState,
    setType: (state, action) => {
      state.type = action.payload
    },
  },
})

export const {
  setStealData,
  setStealTeamDropId,
  setStealTransfer,
  setStealBid,
  setStealDelayedFlag,
  setStealReplaceFlag,
  clearFlags,
  resetSteal,
  setType,
} = stealSlice.actions

export default stealSlice.reducer

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { messages } from '../../../../../messages'
import { CenterContainer } from '../../../styles'

export const NoBiddingTeamCard = () => {
  return (
    <Box
      borderBottom="1px solid lightgray"
      margin="10px 5px 20px 5px">
      <CenterContainer margin="50px 0">
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'lightgray' }}>
          {messages[9].noBidTeam}
        </Typography>
      </CenterContainer>
    </Box>
  )
}

import React from 'react'
import { setUser } from '../../store/slices/userSlice'
import { useSelector } from 'react-redux'

import { Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useNavigate } from 'react-router-dom'
import { resetLeague } from '../../store/slices/leagueSlice'
import { resetManager } from '../../store/slices/managerSlice'
import { resetSteal } from '../../store/slices/stealSlice'
import { resetDraft } from '../../store/slices/draftSlice'
import { allowDashboardAccess } from '../../helpers/utils'

export const ProfileMenu = ({ dispatch }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const { email } = useSelector((state) => state.user)
  const navigate = useNavigate()

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleSignOut = () => {
    dispatch(setUser(null))
    dispatch(resetLeague())
    dispatch(resetManager())
    dispatch(resetSteal())
    dispatch(resetDraft(resetDraft()))
    localStorage.removeItem('authTokens')
    navigate('/')
  }

  return (
    <>
      <IconButton id='myAccountBtn' onClick={handleOpenUserMenu}>
        <AccountCircleIcon sx={{ color: 'white' }} />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {allowDashboardAccess.includes(email) && (
          <MenuItem onClick={handleCloseUserMenu}>
            <Typography textAlign="center" onClick={() => navigate('/admin')}>
              Dashboard
            </Typography>
          </MenuItem>
        )}

        {allowDashboardAccess.includes(email) && <Divider />}

        <MenuItem id='feedbackBtn' onClick={handleCloseUserMenu}>
          <Typography textAlign="center">
            Feedback
          </Typography>
        </MenuItem>

        <MenuItem id='guidesBtn' onClick={handleCloseUserMenu}>
          <Typography textAlign="center">
            Guides
          </Typography>
        </MenuItem>

        <MenuItem id='logoutBtn' onClick={handleCloseUserMenu}>
          <Typography textAlign="center" onClick={handleSignOut}>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

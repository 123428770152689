import { Box, Container, Badge } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getMyStealAttempts } from '../../../api/services/steal'
import { useSelector } from 'react-redux'

const urls = ['/league', '/my-team', '/transactions', '/matchups']

function SecondaryNavBar() {
  const { manager, league } = useSelector((state) => state)
  const navigate = useNavigate()

  const isInURL = (url) => {
    return location.pathname === url
  }

  const convertToLabel = (url) => {
    return url.slice(1).split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  const convertToId = (url) => {
    return url.slice(1)
  }

  const {
    data: transactionsData
  } = useQuery(
    ['my_steals', manager.id],
    () => getMyStealAttempts(manager.id),
    {
      enabled: manager.id > 0,
      retry: false
    }
  )

  const hasAttempts = () => {
    if (transactionsData && transactionsData.received) {
      return transactionsData.received.length > 0
    } else {
      return false
    }
  }

  return (
    <Box
      bgcolor="white"
      zIndex="1"
      left="0"
      width="100%"
      padding="10px 0 5px 0"
    >
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {
            urls.map((url, index) => {
              if (url === '/matchups' && league.is_mock) return null
              return (
                <Box
                  key={index}
                  id={convertToId(url) + 'Tab'}
                  onClick={() => navigate(url)}
                  sx={{
                    color: isInURL(url) ? 'black' : 'slategray',
                    cursor: 'pointer',
                    fontSize: 14,
                    fontWeight: 'bold'
                  }}
                >
                  {url === '/transactions' && hasAttempts() ? (
                    <Badge
                      color="secondary" variant="dot" badgeContent=" "
                      sx={{
                        '& .MuiBadge-dot': {
                          height: 12,
                          minWidth: 12,
                          borderRadius: '50%',
                          marginRight: '-6px',
                          marginTop: '2px'
                        }
                      }}
                    >
                      {convertToLabel(url)}
                    </Badge>
                  ) : (
                    convertToLabel(url)
                  )}
                </Box>
              )
            })}
        </Box>
      </Container>
    </Box>
  )
}

export default SecondaryNavBar

import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { Box } from '@mui/system'

// RESUME BAR
export const ResumeBarContainer = styled(Box)`
  width: 100%;
  z-index: 1;
`

export const ResumeBarButton = styled(Button)`
  min-width: 10em;
  background-color: #d41217;
  white-space: nowrap;
`
export const ResumerBarValue = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 50px;
`

// SIDE MENU
export const SideMenuContainer = styled(Box, {
  shouldForwardProp: (prop) => !['alignright, zindexnull'].includes(prop),
})`
  position: fixed;
  right: ${(props) => props.alignright && 0};
  height: calc(100vh - 56px);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  z-index: ${(props) => (props.zindexnull ? '0' : '1')};
`

export const ScrollingData = styled(Box)`
  height: 100%;
  overflow: scroll;
  padding-bottom: 75px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const SideMenuCard = styled(Box, {
  shouldForwardProp: (prop) => !['clickable', 'hideshadow'].includes(prop),
})`
  width: 65px;
  height: 65px;
  box-shadow: ${({ hideshadow }) =>
    !hideshadow &&
    '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${({ clickable }) => clickable && 'pointer'};
`
export const ManagerCardRow = styled(Box, {
  shouldForwardProp: (prop) => !['isheader'].includes(prop),
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  padding: ${({ isheader }) => (isheader ? '10px' : '5px 10px')};
`

export const ManagerDrawerTitle = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 10px;
`
// DRAFT AREA
export const DraftAreaContainer = styled(Box, {
  shouldForwardProp: (prop) => !['desktop'].includes(prop),
})`
  position: fixed;
  left: 0;
  right: 0;
  margin-left: ${({ desktop }) => (desktop ? 'auto' : '75px')};
  margin-right: ${({ desktop }) => (desktop ? 'auto' : '10px')};
  margin-top: 10px;
  width: ${({ desktop }) => (desktop ? '400px' : 'calc(100vw - 85px)')};
`

export const DraftBody = styled(Box)`
`

export const StickyBox = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: inherit;
`

export const DraftRosterContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  padding: 0 5px;
`

export const DraftRosterFlag = styled(Box)`
  display: flex;
  justify-content: center;
  width: 95%;
  margin-bottom: 10px;
`

// OTHERS
export const VerticalDivider = styled(Box)`
  background-color: grey;
  width: 1px;
  margin-right: 5px;
`
export const ImageContainer = styled.img`
  width: ${(props) => (props.width ? props.width : '40px')};
  padding: 10px 5px;
`
export const CenterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ManagerIndicatorIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 5px;
`
export const TypeCircle = styled(Box)`
  border-radius: 50%;
  margin-left: 2px;
  height: 5px;
  width: 5px;
`

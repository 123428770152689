import { createSlice } from '@reduxjs/toolkit'

const initialState = { name: '', id: 0, is_locked: false }

export const leagueSlice = createSlice({
  name: 'league',
  initialState: initialState,
  reducers: {
    setLeague: (state, action) => {
      return action.payload
    },
    setLockLeague: (state) => {
      state.is_locked = true
    },
    resetLeague: () => initialState,
  },
})

export const { setLeague, setLockLeague, resetLeague } = leagueSlice.actions

export default leagueSlice.reducer

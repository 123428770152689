import React from 'react'
import { DraftSkeleton } from './DraftSkeleton'
import { LeagueSkeleton } from './LeagueSkeleton'
import { MatchupsSkeleton } from './MatchupsSkeleton'
import { TeamSkeleton } from './TeamSkeleton'

export const Skeletons = ({ type }) => {
  return (
    <>
      {type === 'league' && <LeagueSkeleton />}
      {type === 'matchups' && <MatchupsSkeleton />}
      {type === 'draft' && <DraftSkeleton />}
      {!type && <TeamSkeleton />}
    </>
  )
}

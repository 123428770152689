import React from 'react'
import { Button } from '@mui/material'
import { Box, Container } from '@mui/system'
import { Text } from '../../styles/global'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  componentDidCatch(error, info) {
    console.log(error, info)
    this.setState({ hasError: true, error })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage />
    }
    return this.props.children
  }
}

const ErrorMessage = () => {

  const logOut = () => {
    localStorage.clear()
    window.location.href = '/'
  }

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="20px"
      >
        <Box
          width="100%"
          component="img"
          src="/assets/images/captrade-logo.png"
          maxWidth='sm'
        />
        <ReportProblemIcon style={{ fontSize: 50, color: 'red' }} />
        <Text
          maintainsize
          variant="h2"
          textAlign='center'
          sx={{ color: 'primary.main' }}
          margin="20px 0 40px 0"
        >
          The system encountered an error.
        </Text>
        <Button id='ErrorGoBackBtn' variant="outlined" onClick={logOut}>
          Go back
        </Button>
      </Box>
    </Container>
  )
}

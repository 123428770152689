import styled from '@emotion/styled'
import { Skeleton } from '@mui/material'

export const StyledSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => !['mirror'].includes(prop),
})`
  position: absolute;
  left: ${({ mirror }) => (mirror ? 'inherit' : '0')};
  right: ${({ mirror }) => (mirror ? '0' : 'inherit')};
  width: 60px;
  height: 100%;
`

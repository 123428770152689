import React from 'react'
import { ScheduleArea } from './ScheduleArea'
import { StealAttemptForm } from './StealAttemptForm'

export const TeamCardSchedule = (props) => {
  return (
    <>
      <ScheduleArea {...props} />
      <StealAttemptForm {...props} />
    </>
  )
}

import * as React from 'react'
import {
  Grid,
  Skeleton,
} from '@mui/material'
import { Box } from '@mui/system'
import { Text } from '../../../styles/global'
import { messages } from '../../../messages'
import { StealCard } from '../ReceivedAttempts/StealCard'

export default function SentAttempts({
  transactions,
  refetch,
  teamsIsLoading,
  manager,
}) {
  if (teamsIsLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={40}
        sx={{ marginTop: '10px' }}
      />
    )
  }
  return (
    <Box width="100%" position="relative" marginTop={1}>
      <Box id={'receivedBidsBtn'} sx={{ padding: '5px' }} borderBottom="1px solid black">
        <Box display="flex" gap="5px" justifyContent="space-between">
          <Text variant="body3">{messages[3].stealAttemptsMessage}</Text>
          <Text hasprice variant="body3" component="span">
            {messages[3].cashRemainingMessage}
            <span> ${manager?.cash_remaining}</span>
          </Text>
        </Box>
      </Box>

      <Box marginTop={1}>
        {transactions?.sent.length > 0 ? (
          transactions.sent.map((transaction, key) => (
            <Grid item sm={6} md={4} key={key}>
              <StealCard
                transaction={transaction}
                refetchAllTransactions={refetch}
                received={false}
              />
            </Grid>
          ))
        ) : (
          <Box width="100%" sx={{ marginTop: '20px', textAlign: 'center' }}>
            <Text variant="body1" sx={{ fontSize: '1.2rem' }}>
              No pending bids at this time
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

import { Box } from '@mui/system'
import React from 'react'
import { Text } from '../../../styles/global'
import TeamTable from './TeamTable'
import { Grid } from '@mui/material'

export const MainMatchArea = ({ firstMatchup, sortByKind }) => {

  if(!firstMatchup){
    return null
  }

  const team1 = sortByKind(firstMatchup[0])
  const team2 = sortByKind(firstMatchup[1])

  return (
    <Box
      sx={{
        margin: 0,
        marginTop: 1,
        padding: 0,
      }}
      display="flex" alignItems="center" flexDirection="column">
      <Grid container width="100%" alignItems="center" sx={{ borderBottom: '1px solid grey' }}>
        <Grid item xs={4}>
          <Text variant="h6" whiteSpace="nowrap">
            {team1.manager}
          </Text>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <Text variant="h6">
            {team1.total_score} - {team2.total_score}
          </Text>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Text variant="h6" whiteSpace="nowrap" >
            {team2.manager}
          </Text>
        </Grid>
      </Grid>
      <Box
        width="100%"
        display="flex" alignItems="center" margin="10px 0" flexDirection="row">
        <TeamTable matchupTeam={sortByKind(firstMatchup[0])} />
        <Text variant="caption" margin="5px">
        </Text>
        <TeamTable matchupTeam={sortByKind(firstMatchup[1])} reverse />
      </Box>
    </Box>
  )
}

import * as React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

export default function LeagueOrMockToggleButton({ isMock, setIsMock }) {
  return (
    <ToggleButtonGroup
    value={isMock}
    exclusive
    onChange={(e, v) => v !== null && setIsMock(v)}
    aria-label="league filter"
  >
    <ToggleButton color="primary" value={false} aria-label="real leagues">
      Season-Long Leagues
    </ToggleButton>
    <ToggleButton color="primary" value={true} aria-label="mock leagues">
      Mock Drafts
    </ToggleButton>
  </ToggleButtonGroup>
  )
}

import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles'
import { Router } from './router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { checkBuildDateAndLogout } from './helpers/utils'
import { ErrorBoundary } from './components/errorBoundary'

// Log out the user when a new version of the frontend has been deployed (to prevent cache or localstorage errors)
checkBuildDateAndLogout()

const queryClient = new QueryClient()

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#023367',
      },
      secondary: {
        main: '#D41217',
      },
    },
  })
)

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-center"
        theme='colored'
        newestOnTop={true}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App

import React from 'react'
import { Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from 'react-router-dom'
import { messages } from '../../../../../messages'

export const ActionMenu = ({
  isManagerCommissioner,
  draftStatus,
  endDraftAPI,
  // isLoadingEndDraftAPI,
  // isLoadingPauseDraftAPI,
  // isLoadingResumeDraftAPI,
  // isLoadingStartDraftAPI,
  pauseDraftAPI,
  resumeDraftAPI,
  startDraftAPI,
  endIsAllowed,
  onlineManagers
}) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const navigate = useNavigate()

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const handleLeaveDraft = () => {
    navigate('/league')
  }

  return (
    <>
      <IconButton onClick={handleOpenUserMenu}>
        <MenuIcon sx={{ color: 'white' }} />
      </IconButton>
      <Menu
        sx={{ mt: '20px', ml: '20px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {isManagerCommissioner && draftStatus.status === 'in_progress' && (
          [
            <MenuItem key="pause" id='draftPauseMenuBtn' onClick={handleCloseUserMenu} sx={{ justifyContent: 'center' }}>
              <Typography onClick={pauseDraftAPI}>
                {messages[12].pauseDraft}
              </Typography>
            </MenuItem>,
            <Divider key="divider1" />,
          ]
        )}
        {isManagerCommissioner && draftStatus.status === 'paused' && (
          [
            <MenuItem key="resume" id='draftResumeMenuBtn' onClick={handleCloseUserMenu} sx={{ justifyContent: 'center' }} disabled={onlineManagers.length <= 1}>
              <Typography onClick={resumeDraftAPI}>
                {messages[12].resumeDraft}
              </Typography>
            </MenuItem>,
            <Divider key="divider2" />,
          ]
        )}
        {isManagerCommissioner && draftStatus.is_active && endIsAllowed && (
          [
            <MenuItem key="end" id='draftEndMenuBtn' onClick={handleCloseUserMenu} sx={{ justifyContent: 'center' }}>
              <Typography onClick={endDraftAPI}>
                {messages[12].endDraft}
              </Typography>
            </MenuItem>,
            <Divider key="divider3" />,
          ]
        )}
        {isManagerCommissioner && !draftStatus.is_active && draftStatus.status == 'available' && (
          [
            <MenuItem key="start" id='draftStartMenuBtn' onClick={handleCloseUserMenu} sx={{ justifyContent: 'center' }} disabled={onlineManagers.length <= 1}>
              <Typography onClick={startDraftAPI}>
                {messages[12].startDraft}
              </Typography>
            </MenuItem>,
            <Divider key="divider4" />,
          ]
        )}
        <MenuItem id='draftLeaveMenuBtn' onClick={handleCloseUserMenu} sx={{ justifyContent: 'center' }}>
          <Typography onClick={handleLeaveDraft}>
            {messages[10].leaveDraft}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

import * as React from 'react'
import {
  Box,
} from '@mui/material'
import { Text } from '../../../styles/global'
import { Skeletons } from '../../../components/Skeletons'
import { messages } from '../../../messages'
import { NoTeamCard } from './NoTeamCard'
import TeamRow from './TeamRow'
import { useSelector } from 'react-redux'

export default function MyTeamsRoster({
  teams,
  myTeamPageRefetch,
  teamsIsLoading,
  hideEmpty,
  isStealable = false,
}) {
  if (teamsIsLoading) {
    return <Skeletons />
  }
  const { type } = useSelector((state) => state.steal)
  const textHandler = () => {
    if (type === 'roster') {
      return messages[0].noAvailableTeamError
    } else {
      return messages[0].noTeamsSelectedError
    }
  }

  const incompleteTeamHandler = () => {
    if (teams.length < 4 && !hideEmpty && type == null) {
      return (
        <>
          {teams.length <= 3 && <NoTeamCard />}
          {teams.length <= 2 && <NoTeamCard />}
          {teams.length <= 1 && <NoTeamCard />}
          {teams.length === 0 && <NoTeamCard />}
        </>
      )
    }
  }

  const filterTeams = (team) => {
    if (type === 'offenses') {
      return team.team.is_offense
    } else if (type === 'defenses') {
      return !team.team.is_offense
    }
    return true
  }

  return (
    <>
      <Box marginTop="5px">
        {incompleteTeamHandler()}
        {teams?.length ? (
          teams
            .filter(team => filterTeams(team))
            .map((team, key) => (
              <TeamRow
                team={team}
                myTeamPageRefetch={myTeamPageRefetch}
                isStealable={isStealable}
                key={key}
                id={`availableTeam${key + 1}`}
              />
            ))
        ) : (
          <Text textAlign="center" color="gray">
            {textHandler()}
          </Text>
        )}
      </Box>
    </>
  )
}

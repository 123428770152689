import { Container, Box, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import LeagueTable from '../components/LeagueTable'

import { useDispatch, useSelector } from 'react-redux'
import LeagueFormDialog from './LeagueFormDialog'
import { getAllLeagues } from '../../../api/services/leagues'
import { useQuery } from '@tanstack/react-query'
import { updateUserLeagues } from '../../../store/slices/userSlice'
import LeagueOrMockToggleButton from './LeagueFormDialog/LeagueOrMockToggleButton'

export const MyLeagues = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { leagues } = useSelector((state) => state.user)
  const [isMock, setIsMock] = useState(false)

  const { data: updatedleagues, refetch: leaguesRefetch } = useQuery(
    ['all-leagues'],
    () => getAllLeagues(),
    {
      onSuccess: (leaguesData) => {
        dispatch(updateUserLeagues(leaguesData))
      },
    }
  )

  return (
    <Container maxWidth="md">
      <Box padding="10px 0" display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems="center">
        <Box marginBottom={isMobile ? '10px' : '0'}>
          <LeagueFormDialog isMock={isMock} setIsMock={setIsMock} leaguesRefetch={leaguesRefetch} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LeagueOrMockToggleButton isMock={isMock} setIsMock={setIsMock} />
        </Box>
      </Box>

      {(updatedleagues ? updatedleagues : leagues)
        .filter((league) => league.is_mock === isMock)
        .map((leagueData, key) => (
          <LeagueTable leagueData={leagueData} key={key} />
        ))}
    </Container>
  )
}

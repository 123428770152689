import * as React from 'react'
import {
  Skeleton,

} from '@mui/material'
import { Box } from '@mui/system'

import { StealCard } from './StealCard'
import { messages } from '../../../messages'
import TooltipDialog from './TooltipDialog'
import { Text } from '../../../styles/global'

export default function ReceivedAttempts({
  transactions,
  transactionsIsLoading,
  manager,
  myTeamPageRefetch,
}) {

  const myTeamPageRefetchHandler = () => {
    myTeamPageRefetch()
  }

  if (transactionsIsLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={40}
        sx={{ marginTop: '10px' }}
      />
    )
  }

  return (
    <Box width="100%" position="relative" marginTop={1}>
      <Box id={'receivedBidsBtn'} sx={{ padding: '5px' }} borderBottom="1px solid black">
        <Box display="flex" gap="5px" justifyContent="space-between">
          <Text variant="body3">{messages[3].stealReceivedMessage}</Text>
          <Text hasprice variant="body3" component="span">
            {messages[3].cashRemainingMessage}
            <span> ${manager?.cash_remaining}</span>
          </Text>
        </Box>
      </Box>

      <Box marginTop={1}>
        {transactions?.received.length > 0 ? (
          <>
            {transactions.received.map((transaction, key) => (
              <StealCard
                transaction={transaction}
                key={key}
                refetchAllTransactions={myTeamPageRefetchHandler}
                received={true}
              />
            ))}
            <TooltipDialog />
          </>
        ) : (
          <Box width="100%" sx={{ marginTop: '20px', textAlign: 'center' }}>
            <Text variant="body1" sx={{ fontSize: '1.2rem' }}>
              No received bids at this time
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

import styled from '@emotion/styled'

const LoginLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  a {
    color: #023367;
    font-weight: bold;
    flex: 1;

    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }

    &:hover, &:focus {
      text-decoration: none;
    }
  }
`

export default LoginLinkContainer

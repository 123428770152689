import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

export const Text = styled(Typography, {
  shouldForwardProp: (prop) =>
    !['color', 'hasprice', 'isbold', 'isgray', 'maintainsize'].includes(prop),
})`
  font-weight: ${({ isbold }) => (isbold ? 'initial' : 500)};
  color: ${({ isgray, color }) => (isgray ? 'gray' : color)};
  span {
    color: ${({ hasprice }) => (hasprice ? 'green' : 'inherit')};
    margin-left: 5px;
  }
  @media (min-width: 768px) {
    font-size: ${({ maintainsize }) => !maintainsize && '1rem'};
  }
`
export const ColumnContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isgray',
})`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  color: ${({ isgray }) => (isgray ? '#929CAF' : 'black')};
  width: fit-content;
`

import React from 'react'
import { MenuItem, Badge } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getMyStealAttempts } from '../../../api/services/steal'
import { getDraftInfo } from '../../../api/services/draft'

const LeagueSelectorItem = ({ league, manager, changeLeagueHandler, setHasAvailableDraft, isMock = false }) => {
  const { data: transactionsData } = useQuery(
    ['my_steals', manager.id],
    () => getMyStealAttempts(manager.id),
    {
      enabled: manager.id > 0,
      retry: false
    }
  )

  const { data: draftData } = useQuery(
    ['draft_info', league.id],
    () => getDraftInfo(league.id),
    {
      enabled: league.id > 0 && league.is_locked,
      retry: false,
      onSuccess: (data) => {
        if (data && data.draft && data.draft.status === 'available') {
          setHasAvailableDraft(true)
        }
      }
    }
  )

  const isDraftAvailable = () => {
    return draftData && draftData.draft && draftData.draft.status === 'available'
  }

  const hasAttempts = () => {
    if (transactionsData && transactionsData.received) {
      return transactionsData.received.length > 0
    } else {
      return false
    }
  }

  return (
    <MenuItem
      sx={{
        margin: 0,
        maxHeight: '26px',
        marginLeft: '8px',
        padding: '2px',
        fontWeight: 'bold'
      }}
      onClick={() => changeLeagueHandler(league.id)}
      id={`leagueSelector_${league.id}`}
      key={league.id}
      value={league.id}
    >
      {hasAttempts() || isDraftAvailable() ? (
        <Badge
          color="secondary"
          variant="dot"
          sx={{
            '& .MuiBadge-dot': {
              height: 12,
              minWidth: 12,
              borderRadius: '50%',
              marginRight: '-5px',
              marginTop: '3px'
            },
          }}
        >
          {league.name}
        </Badge>
      ) : (
        `${isMock ? 'MOCK-' : ''}${league.name}`
      )}
    </MenuItem>
  )
}

export default LeagueSelectorItem

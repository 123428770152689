import React, { useState, useEffect, useRef } from 'react'
// import { AppBar, Chip, Toolbar } from '@mui/material'
import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { ResumeBarButton, ResumeBarContainer } from '../../styles'
import {
  endDraft,
  pauseDraft,
  resumeDraft,
  startDraft,
} from '../../../../api/services/draft'
import bellSound from '../../../../assets/sounds/bell.mp3'
import { isManagerCommissioner } from '../../../../helpers/utils'
// import TimerButtons from '../TimerButtons'
import EndDraftDialog from '../EndDraftDialog'
import { ActionMenu } from './ActionMenu/ActionMenu'
import { messages } from '../../../../messages'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

// Fix = Timer needs to update after the refetch (async)
export default function ResumeBar() {
  const { manager, league } = useSelector((state) => state)
  const { draftStatus, auction, endIsAllowed, onlineManagers, managers } = useSelector((state) => state.draft)
  const [bell] = useState(new Audio(bellSound))
  const [endDraftDialogOpen, setEndDraftDialogOpen] = useState(false)
  const [bottomBarColor, setBottomBarColor] = useState('primary.main')
  const [myManager, setMyManager] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const myManagerInfo = managers.find(
      (draftManager) => draftManager.id === manager.id
    )
    setMyManager(myManagerInfo)
  }, [manager, managers])

  const hasMounted = useRef(false)

  //   Start Draft
  const {
    mutate: startDraftAPI,
    isLoading: isLoadingStartDraftAPI,
  } = useMutation(() => startDraft(league.id), {
    onSuccess: () => {
    },
  })

  //   Pause Draft
  const {
    mutate: pauseDraftAPI,
    isLoading: isLoadingPauseDraftAPI,
  } = useMutation(() => pauseDraft(draftStatus.id), {
    onError: (error) => {
      toast.error(error?.response.data.message)
    },
  })

  //   Resume Draft
  const {
    mutate: resumeDraftAPI,
    isLoading: isLoadingResumeDraftAPI,
  } = useMutation(() => resumeDraft(draftStatus.id), {
    onError: (error) => {
      toast.error(error?.response.data.message)
    },
  })

  //   End Draft
  const {
    mutate: endDraftAPI,
    isLoading: isLoadingEndDraftAPI,
  } = useMutation(() => endDraft(draftStatus.id), {
    onSuccess: () => {
    },
    onError: (error) => {
      toast.error(error?.response.data.message)
    },
  })

  const closeEndDraftDialog = () => {
    endDraftAPI()
    setEndDraftDialogOpen(false)
  }

  // const chipNameHandler = (label) => {
  //   let newLabel = label
  //     .replace(/_/g, ' ')
  //     .replace(/^\w|\s\w/g, (c) => c.toUpperCase())

  //   return newLabel
  // }

  // handles manager turn alerts (bell sound and bottom bar color)
  useEffect(() => {
    // Skip the effect on the initial mount
    if (!hasMounted.current) {
      hasMounted.current = true
      return
    }
    if (manager.id === draftStatus.nominating_manager && draftStatus.status == 'in_progress' && draftStatus.period == 'nominating') {
      bell.play()
    } else {
      setBottomBarColor('primary.main')
    }
  }, [draftStatus.nominating_manager, draftStatus.status])

  useEffect(() => {
    if (draftStatus.period == 'bidding') {
      if (manager.id === auction.best_bidder) {
        setBottomBarColor('green')
      } else {
        setBottomBarColor('primary.main')
      }
    }
  }, [auction.best_bidder, draftStatus.status])

  const nominating_manager = managers.find(manager => manager.id == draftStatus.nominating_manager)

  function getCurrentNominationText(myManager) {

    const offenseFull = myManager.offense_teams.length >= 2
    const defenseFull = myManager.defense_teams.length >= 2

    if (offenseFull && !defenseFull) {
      return 'Nominate a Defense'
    }
    if (defenseFull && !offenseFull) {
      return 'Nominate an Offense'
    }
    return 'Nominate a team'
  }


  return (
    <>
      <EndDraftDialog
        open={endDraftDialogOpen}
        setOpen={setEndDraftDialogOpen}
        onConfirm={closeEndDraftDialog}
      />
      <ResumeBarContainer>
        {/* Online users full roster alert */}
        {/* <CustomizedSnackbar
          isSuccess={endIsAllowed}
          successMessage={'All online users are with a full roster!'}
        /> */}

        {/* Not free slots to resume draft */}

        <AppBar
          position="fixed"
          sx={{ backgroundColor: bottomBarColor, height: '55px', top: 0 }}
        >
          <Toolbar
            data-testid="toolbar"
            sx={{
              display: 'flex',
              paddingLeft: '16px !important',
              paddingRight: '16px !important',
              minHeight: '55px !important'
            }}
          >
            <div>
              <ActionMenu
                isManagerCommissioner={isManagerCommissioner(manager.id, draftStatus.auctioneer)}
                draftStatus={draftStatus}
                endDraftAPI={() => setEndDraftDialogOpen(true)}
                isLoadingEndDraftAPI={isLoadingEndDraftAPI}
                isLoadingPauseDraftAPI={isLoadingPauseDraftAPI}
                isLoadingResumeDraftAPI={isLoadingResumeDraftAPI}
                isLoadingStartDraftAPI={isLoadingStartDraftAPI}
                pauseDraftAPI={pauseDraftAPI}
                resumeDraftAPI={resumeDraftAPI}
                startDraftAPI={startDraftAPI}
                endIsAllowed={endIsAllowed}
                onlineManagers={onlineManagers}
                id="draftMenuIcon"
              />
            </div>
            <Box display="flex" alignItems="center" justifyContent="center" width="calc(100% - 56px)">
              {draftStatus.is_active || draftStatus.status == 'finished'
                ?
                <Typography variant="h6">
                  {draftStatus.period == 'nominating' && manager.id === draftStatus.nominating_manager && myManager && getCurrentNominationText(myManager)}
                  {draftStatus.period == 'nominating' && manager.id !== draftStatus.nominating_manager && `${nominating_manager.name} is nominating a team`}
                  {draftStatus.period == 'bidding' && manager.id === auction.best_bidder && 'Highest bid'}
                  {draftStatus.period == 'bidding' && manager.id !== auction.best_bidder && 'Auction in progress'}
                  {draftStatus.status == 'paused' && 'Paused'}
                </Typography>
                : draftStatus.status == 'finished' && isManagerCommissioner(manager.id, draftStatus.auctioneer)
                  ?
                  <ResumeBarButton
                    variant="contained"
                    disabled={isLoadingEndDraftAPI || !endIsAllowed}
                    id='draftResumeBtn'
                    onClick={endDraftAPI}
                    data-testid="end-btn"
                  >
                    {messages[12].endDraft}
                  </ResumeBarButton>
                  :
                  draftStatus.status !== 'finished' && isManagerCommissioner(manager.id, draftStatus.auctioneer) &&
                  <ResumeBarButton
                    variant="contained"
                    id='draftStartBtn'
                    onClick={startDraftAPI}
                    disabled={isLoadingStartDraftAPI}
                    data-testid="start-btn"
                  >
                    {messages[12].startDraft}
                  </ResumeBarButton>}
              {draftStatus.status == 'finished' &&
                <ResumeBarButton
                  variant="contained"
                  id='leaveDraftBtn'
                  onClick={() => {
                    navigate('/league')
                  }}
                  disabled={isLoadingStartDraftAPI}
                  data-testid="start-btn"
                >
                  {messages[10].leaveDraft}
                </ResumeBarButton>}
            </Box>
            {/* If it's commissioner, show buttons */}
            {/* {isManagerCommissioner(manager.id, draftStatus.auctioneer) && (
              <TimerButtons
                draftStatus={draftStatus}
                endDraftAPI={() => setEndDraftDialogOpen(true)}
                isLoadingEndDraftAPI={isLoadingEndDraftAPI}
                isLoadingPauseDraftAPI={isLoadingPauseDraftAPI}
                isLoadingResumeDraftAPI={isLoadingResumeDraftAPI}
                isLoadingStartDraftAPI={isLoadingStartDraftAPI}
                pauseDraftAPI={pauseDraftAPI}
                resumeDraftAPI={resumeDraftAPI}
                startDraftAPI={startDraftAPI}
                endIsAllowed={endIsAllowed}
              />
            )} */}

            {/* If it's not commissioner, show  */}
            {/* {!isManagerCommissioner(manager.id, draftStatus.auctioneer) && (
              <>
                <Chip
                  label={'Draft ' + chipNameHandler(draftStatus.status)}
                  color="secondary"
                />
                {draftStatus.period && (
                  <Chip
                    label={chipNameHandler(draftStatus.period)}
                    color="secondary"
                    sx={{ marginLeft: '10px' }}
                  />
                )}
              </>
            )} */}
          </Toolbar>
        </AppBar>
      </ResumeBarContainer>
    </>
  )
}

import { Button, Hidden, Skeleton, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { resetDraft } from '../../../store/slices/draftSlice'
import { changeCurrentLeague } from '../../../helpers/utils'
import { setLeague } from '../../../store/slices/leagueSlice'
import { setManager } from '../../../store/slices/managerSlice'
import { setStealData } from '../../../store/slices/stealSlice'
import { setDraftManagers } from '../../../store/slices/draftSlice'

// TODO: Delete "leave draft" part if not needed anymore

export const ResponsiveLinkButton = ({
  text,
  leagueUpdatedData,
  leagueUpdatedDataLoading,
  leagueId,
  isGoBack,
  disabled = false,
  disabledReason = ''
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state)

  if (leagueUpdatedDataLoading) {
    return <Skeleton variant="rectangular" height="50px" />
  }

  if (
    (leagueUpdatedData?.draft_status === null ||
      (leagueUpdatedData?.draft_status === 'finished' && !isGoBack))
  ) {
    return null
  }

  const enterDraftHandler = () => {
    // Before changing the url, we must change the current selected league
    const { selectedLeague, selectedManager } = changeCurrentLeague(
      user,
      leagueId
    )
    dispatch(setLeague(selectedLeague))
    dispatch(setDraftManagers(leagueUpdatedData.managers))
    dispatch(setManager(selectedManager))
    dispatch(setStealData(selectedManager))

    // Now, changing the url
    navigate('/draft')
  }

  const leaveDraftHandler = () => {
    navigate('/league')
  }

  return (
    <>
      <Hidden mdUp>
        <Tooltip title={disabled ? disabledReason : ''}>
          <span>
            <Button
              sx={{ marginBottom: 1 }}
              disabled={disabled}
              variant="outlined"
              id={leagueId ? 'enterDraftBtn' : 'exitDraftBtn'}
              onClick={leagueId ? enterDraftHandler : leaveDraftHandler}
              fullWidth
            >
              {text}
            </Button>
          </span>
        </Tooltip>
      </Hidden>
      <Hidden mdDown>
        <Box
          display="flex"
          justifyContent={isGoBack ? 'center' : 'flex-end'}
          width={'100%'}
          height="fit-content"
        >
          <Tooltip title={disabled ? disabledReason : ''}>
            <span>
              <Button
                disabled={disabled}
                variant="outlined"
                id={leagueId ? 'enterDraftBtnMd' : 'exitDraftBtnMd'}
                onClick={leagueId ? enterDraftHandler : leaveDraftHandler}
              >
                {text}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Hidden>
    </>
  )
}

import React from 'react'
import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { isTokenValid } from '../helpers/utils'

export const ProtectedRoutes = () => {
  if (!isTokenValid()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" state={{ from: history.location }} />
  }

  // authorized so return child components
  return <Outlet />
}

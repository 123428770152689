import { TableCell, Typography } from '@mui/material'
import React from 'react'

export const TeamTableCell = ({
  firstTeam,
  secondTeam,
  pointsAgainst,
  pointsScored,
  isDefense,
}) => {
  const teamPrefix = isDefense ? 'DEF-' : 'OFF-'
  const fistTeamHandler = () => {
    if (firstTeam) {
      return (
        <>
          {teamPrefix}{firstTeam.abbreviation} <span>${firstTeam?.market_value}</span>
        </>
      )
    } else {
      return 'No team'
    }
  }
  const secondTeamHandler = () => {
    if (secondTeam) {
      return (
        <>
          {teamPrefix}{secondTeam.abbreviation} <span>${secondTeam?.market_value}</span>
        </>
      )
    } else {
      return 'No team'
    }
  }
  return (
    <TableCell align="center" style={{ padding: '5px', width: '20%' }}>
      <Typography
        display="block"
        component="span"
        variant="caption"
        sx={{
          color: isDefense ? 'primary.main' : 'secondary.main',
        }}
      >
        {fistTeamHandler()}
      </Typography>
      <Typography
        display="block"
        component="span"
        variant="caption"
        sx={{
          color: isDefense ? 'primary.main' : 'secondary.main',
        }}
      >
        {secondTeamHandler()}
      </Typography>

      {isDefense ? (
        <Typography variant="caption">Pts agt: {pointsAgainst}</Typography>
      ) : (
        <Typography variant="caption">Pts for: {pointsScored}</Typography>
      )}
    </TableCell>
  )
}

import { Box } from '@mui/system'
import React from 'react'
import { Text } from '../../../../../styles/global'

export const MatchCard = ({ reverse, matchupTeam }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      padding="3px"
      width="50%"
      minHeight="120px"
    >
      <Box
        display="flex"
        flexDirection={reverse ? 'row-reverse' : 'row'}
        justifyContent="space-between"
        alignItems="center"
        marginBottom="5px"
      >
        <Text maxWidth="75%" isbold fontSize={12} noWrap>
          {matchupTeam.manager}
        </Text>
        <Text isbold>
          {matchupTeam.total_score}
        </Text>
      </Box>
      {matchupTeam.teams.map((nflTeam) => (
        <Box
          key={nflTeam.abbreviation}
          display="flex"
          flexDirection={reverse ? 'row-reverse' : 'row'}
          justifyContent=""
        >
          <Text
            variant="caption"
            textAlign={reverse ? 'right' : 'left'}
            marginRight={reverse ? '3px' : '0'}
            marginLeft={reverse ? '0' : '3px'}
            fontSize={10}
            width='60%'
          >
            {nflTeam.is_offense ? 'Off' : 'Def'}-{nflTeam.abbreviation}
          </Text>
          <Text variant="caption">{nflTeam.score}</Text>
        </Box>
      ))}
    </Box>
  )
}

import { LinearProgress } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { getTeamSchedule } from '../../../../../api/services/team'
import { Text } from '../../../../../styles/global'
import { ScheduleTeam } from './ScheduleTeam'
import { Box } from '@mui/system'
import { messages } from '../../../../../messages'

export const ScheduleArea = ({ collapsedTeam, teamId, collapsedTeamId }) => {
  // Only call team schedule Accordion is opened.
  useEffect(() => {
    if (teamId === collapsedTeamId) {
      refetch()
    }
  }, [collapsedTeamId])

  // Get team schedule
  const { data, refetch, isLoading, isError } = useQuery(
    ['schedule', collapsedTeam.team.id],
    () => getTeamSchedule(collapsedTeam.team.id),
    {
      enabled: false,
      retry: false,
    }
  )
  const teamisOffense = collapsedTeam.team.is_offense

  const scheduleAreaHandler = () => {
    if (isLoading) {
      return <LinearProgress />
    } else if (isError) {
      return <Text marginBottom="20px">{messages[1].noScheduleFound}</Text>
    } else {
      return (
        <Box marginTop="10px" display="flex" overflow="scroll" maxWidth="100%">
          {data.map((match, key) => (
            <ScheduleTeam match={match} teamIsOffense={teamisOffense} key={key} />
          ))}
        </Box>
      )
    }
  }
  return <>{scheduleAreaHandler()}</>
}

import { axiosClient } from '../axios'

export const getDraftInfo = async (leagueId) => {
  var draft = await axiosClient.get(`/draft/${leagueId}/info`)
  return draft.data
}

export const getDraftManagersOnly = async (draftId) => {
  var draft = await axiosClient.get(`/draft/${draftId}/participants`)
  return draft.data
}

export const getDraftAvailableTeamsOnly = async (draftId) => {
  var draft = await axiosClient.get(`/draft/${draftId}/teams-at-auction`)
  return draft.data
}

export const getDraftInfoOnly = async (draftId) => {
  var draft = await axiosClient.get(`/draft/${draftId}/status`)
  return draft.data
}

export const startDraft = async (leagueId) => {
  var draft = await axiosClient.post(`/draft/${leagueId}/start-draft`)
  return draft.data
}

export const pauseDraft = async (draftId) => {
  var draft = await axiosClient.post(`/draft/${draftId}/pause`)
  return draft.data
}

export const resumeDraft = async (draftId) => {
  var draft = await axiosClient.post(`/draft/${draftId}/resume`)
  return draft.data
}

export const endDraft = async (draftId) => {
  var draft = await axiosClient.post(`/draft/${draftId}/end-draft`)
  return draft.data
}

export const nominateTeamDraft = async (teamId) => {
  var teamNominated = await axiosClient.post(`/draft/${teamId}/nominate`)
  return teamNominated.data
}

export const bidOnTeam = async (bidValue, auctionId) => {
  var bid = await axiosClient.post(`/draft/${auctionId}/bid/${bidValue}`)
  return bid.data
}

import { Skeleton } from '@mui/material'
import React from 'react'

export const TeamSkeleton = () => {
  return (
    <>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={40}
        sx={{ marginTop: '20px' }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={40}
        sx={{ marginTop: '10px' }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={140}
        sx={{ marginTop: '10px' }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={140}
        sx={{ marginTop: '10px' }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={140}
        sx={{ marginTop: '10px ' }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={140}
        sx={{ marginTop: '10px ' }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={140}
        sx={{ marginTop: '10px ' }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={40}
        sx={{ marginTop: '10px' }}
      />
    </>
  )
}

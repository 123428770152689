import { Box } from '@mui/material'
import React from 'react'

function SportsDataBadge() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '15px'
      }}
    >
      <a href="https://sportsdata.io" target="_blank" rel="noreferrer">
        <img
          src="https://sportsdata.io/assets/images/badges/sportsdataio_dark_ss_300.png?v=1"
          alt="Sports Data Provider"
        />
      </a>
    </Box>
  )
}

export default SportsDataBadge

import { Divider, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Text } from '../../../../../../styles/global'
import { ManagerIndicatorIcon, ManagerCardRow } from '../../../../styles'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'

export const ManagerCard = ({
  managerData,
  isManagerTurn,
  isManagerMe,
  isManagerBestBidder,
  textColorHandler,
  onlineManagers,
}) => {

  return (
    <Box margin="15px 20px" width="200px">
      <Paper elevation={3}>
        <ManagerCardRow
          bgcolor={isManagerMe(managerData.id) && 'primary.main'}
          isheader
        >
          <Box display="flex" alignItems="center">
            <Typography
              color={textColorHandler(managerData.id)}
              sx={{
                fontWeight: !onlineManagers.includes(managerData.id) && '500',
              }}
            >
              {managerData.name}
            </Typography>
          </Box>
          {isManagerTurn(managerData.id) &&
            <ManagerIndicatorIcon
              src="/assets/icons/n-nominator.png"
              data-testid="nominating-manager"
            />
          }
          {isManagerBestBidder(managerData.id) &&
          <PaidOutlinedIcon color='success' fontSize='small'
          sx={{ fontSize: '1.05rem', marginRight: '4px' }}
          data-testid="best-bider-manager"
        />
          }
          <Text isbold color="green">
            ${managerData.cash_remaining}
          </Text>
        </ManagerCardRow>
        <Divider />
        <ManagerCardRow>
          {managerData.offense_teams.map((offenseTeam, key) => (
            <Text
              sx={{
                color: 'secondary.main',
              }}
              hasprice
              key={key}
            >
              Off-{offenseTeam.abbreviation}
              <span>${offenseTeam.market_value}</span>
            </Text>
          ))}
        </ManagerCardRow>
        <ManagerCardRow>
          {managerData.defense_teams.map((defenseTeam, key) => (
            <Text
              sx={{
                color: 'primary.main',
              }}
              hasprice
              key={key}
            >
              Def-{defenseTeam.abbreviation}
              <span>${defenseTeam.market_value}</span>
            </Text>
          ))}
        </ManagerCardRow>
      </Paper>
    </Box>
  )
}

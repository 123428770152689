import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import { useDispatch, useSelector } from 'react-redux'
import { LeagueSelector } from './LeagueSelector'
import { ProfileMenu } from './ProfileMenu'
import { Divider } from '@mui/material'
import { MyCashLeft } from './MyCashLeft'
import SecondaryNavBar from './SecondaryNavBar'

function Navbar({ showCashLeft, managerData, leagueUpdatedDataFetching }) {
  const dispatch = useDispatch()

  const { league: currentLeague } = useSelector((state) => state)

  return (
    <>
      <AppBar elevation={0} position="sticky">
        <Container maxWidth="lg">
          <Box display="flex" justifyContent="space-between" height="50px">
            <LeagueSelector dispatch={dispatch} />
            <Box display="flex" alignItems="center">
              {currentLeague.name}
            </Box>
            <ProfileMenu dispatch={dispatch} />
          </Box>
          <Divider />
        </Container>
        <SecondaryNavBar />
        {showCashLeft && (
          <MyCashLeft
            managerData={managerData}
            leagueUpdatedDataFetching={leagueUpdatedDataFetching}
          />
        )}
      </AppBar>
    </>
  )
}
export default Navbar

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import leagueSlice from './slices/leagueSlice'
import managerSlice from './slices/managerSlice'
import userReducer from './slices/userSlice'
import stealSlice from './slices/stealSlice'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import draftSlice from './slices/draftSlice'
import { v4 as uuidv4 } from 'uuid' // import the uuid library to generate a unique ID

const checkUuid = () => {
  if (!localStorage.getItem('uuuid')) {
    localStorage.setItem('uuuid', uuidv4())
  }
  return localStorage.getItem('uuuid')
}

const persistConfig = {
  key: 'root' + checkUuid(),
  storage,
}

const reducer = combineReducers({
  user: userReducer,
  league: leagueSlice,
  manager: managerSlice,
  steal: stealSlice,
  draft: draftSlice,
})

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'

export default function Subnavbar({ initialWeek, week, newWeekHandler }) {
  const myRef = React.useRef(null)

  const displayButtonHandler = (id) => {
    const isCurrentWeek = id === week
    return (
      <Button
        ref={isCurrentWeek ? myRef : null}
        sx={{
          width: 'max-content',
          border: 'none',
          padding: '0px',
          fontWeight: 'bold',
          textTransform: 'none',
          color: 'black',
          '&:hover': {
            border: 'none',
            backgroundColor: 'lightgray',
          },
        }}
        key={id}
        id={isCurrentWeek ? null : `matchupsWeekBtn_${id}`}
        onClick={isCurrentWeek ? null : () => newWeekHandler(id)}
      >
        {isCurrentWeek ? `Week ${id}` : id}
      </Button>
    )
  }

  React.useEffect(() => {
    if (myRef.current?.scrollIntoView != null) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [])
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      overflow="scroll"
      padding="10px"
    >
      <ButtonGroup
        size="small"
        aria-label="small button group"
      >
        {Array(19)
          .fill()
          .map((_, i) => i >= initialWeek && displayButtonHandler(i))}
      </ButtonGroup>
    </Box>
  )
}

import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Slide from '@mui/material/Slide'
import { messages } from '../../../../../messages'
import { useDispatch } from 'react-redux'
import {
  clearFlags,
  setStealDelayedFlag,
  setStealReplaceFlag,
} from '../../../../../store/slices/stealSlice'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function RetryDialog({
  retryType,
  collapsedTeamHasOwner,
  mutate,
  resetRetry,
}) {
  const dispatch = useDispatch()
  const messageHandler = () => {
    if (retryType === 'DELAYED_DROP' && collapsedTeamHasOwner) {
      dispatch(setStealDelayedFlag())
      return messages[8].retryClaimed.replace('This team', 'Team to Drop')
    } else if (retryType === 'DELAYED_DROP' && !collapsedTeamHasOwner) {
      dispatch(setStealDelayedFlag())
      return messages[8].retryUnownedClaimed.replace('The team', 'Team to Drop')
    } else if (retryType === 'DELAYED_STEAL' && collapsedTeamHasOwner) {
      dispatch(setStealDelayedFlag())
      return messages[8].retryClaimed.replace('This team', 'Team to Steal')
    } else if (retryType === 'DELAYED_STEAL' && !collapsedTeamHasOwner) {
      dispatch(setStealDelayedFlag())
      return messages[8].retryUnownedClaimed.replace('The team', 'Team to Steal')
    } else if (retryType === 'DUPLICATED') {
      dispatch(setStealReplaceFlag())
    }
    return messages[8].duplicatedClaimed
  }  
  

  const retryMutate = () => {
    mutate()
    resetRetry()
  }

  const cancelRetryMutate = () => {
    dispatch(clearFlags())
    resetRetry()
  }

  return (
    <div>
      <Dialog
        open={retryType != null}
        TransitionComponent={Transition}
        keepMounted
        onClose={resetRetry}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {messageHandler()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelRetryMutate}>Cancel</Button>
          <Button onClick={retryMutate}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

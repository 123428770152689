import { useTheme } from '@emotion/react'
import { Box, useMediaQuery } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDraftAvailableTeamsOnly,
  getDraftInfo,
  getDraftInfoOnly,
  getDraftManagersOnly,
} from '../../api/services/draft'
import { Skeletons } from '../../components/Skeletons'
import {
  setDraft,
  setDraftAvailableTeams,
  setDraftManagers,
  setDraftManagersOnlineList,
  setDraftStatus,
} from '../../store/slices/draftSlice'
import { DraftArea } from './components/DraftArea'
import OfflineDialog from './components/OfflineDialog'
import ResumeBar from './components/ResumeBar'
import { SideMenuManagers } from './components/SideMenuManagers'
import { DraftAreaContainer } from './styles'
import { setSelectedTeam, setTeamEnqueued } from '../../store/slices/draftSlice'
//import { SideMenuTeams } from './components/SideMenuTeams'

export const Draft = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const dataFetchedRef = useRef(false)
  const { league, draft } = useSelector((state) => state)
  const { auction, selectedTeam, teamEnqueued } = useSelector((state) => state.draft)

  const [isUserOffline, setIsUserOffline] = useState(false)

  // --------------- Clearing out selected team  ---------------------

  useEffect(() => {
    if (selectedTeam && selectedTeam.team.id === auction.team_auctioned) {
      dispatch(setSelectedTeam(null))
      if (teamEnqueued) {
        dispatch(setTeamEnqueued())
      }
    }
  }, [draft])

  // refetch the whole draft
  const { isFetching } = useQuery(['my-draft'], () => getDraftInfo(league.id), {
    retry: false,
    enabled: league.id !== draft.draftStatus.league,
    onSuccess: (draft) => {
      dispatch(setDraft(draft))
    },
  })

  // refetch draft info only
  const { refetch: draftOnlyRefetch } = useQuery(
    ['my-draft-info-only'],
    () => getDraftInfoOnly(draft.draftStatus.id),
    {
      retry: false,
      enabled: false,
      onSuccess: (draft) => {
        dispatch(setDraftStatus(draft))
      },
    }
  )

  // refetch draft managers only
  const { refetch: draftManagersRefetch } = useQuery(
    ['my-draft-managers-only'],
    () => getDraftManagersOnly(draft.draftStatus.id),
    {
      retry: false,
      enabled: false,
      onSuccess: (draft) => {
        dispatch(setDraftManagers(draft.managers))
        dispatch(setDraftManagersOnlineList(draft.active))
      },
    }
  )

  // refetch draft available teams only
  const { refetch: draftavailableTeamsRefetch } = useQuery(
    ['my-draft-available-teams-only'],
    () => getDraftAvailableTeamsOnly(draft.draftStatus.id),
    {
      retry: false,
      enabled: false,
      onSuccess: (draft) => {
        dispatch(setDraftAvailableTeams(draft))
      },
    }
  )

  useEffect(() => {
    if (dataFetchedRef.current) return
    dataFetchedRef.current = true

    // refetch data status on page reload
    if (draft.isDraftUpdate) {
      draftOnlyRefetch()
    }
  }, [])


  if (isFetching || !draft.isDraftUpdate) {
    return <Skeletons type="draft" />
  }

  return (
    <>
      <ResumeBar />
      <Box paddingTop="55px" maxHeight="calc(100vh - 55px)">
        <OfflineDialog
          open={isUserOffline}
          handleClose={() => setIsUserOffline(false)}
        />
        <SideMenuManagers />
        <DraftAreaContainer desktop={isDesktop}>
          <DraftArea
            draftOnlyRefetch={draftOnlyRefetch}
            draftManagersRefetch={draftManagersRefetch}
            draftavailableTeamsRefetch={draftavailableTeamsRefetch}
          />
        </DraftAreaContainer>
        {/* <SideMenuTeams draftOnlyRefetch={draftOnlyRefetch} /> */}
      </Box>
    </>
  )
}

// frontend/src/api/services/nfl.js
import { axiosClient } from '../axios'

export const startNFLUpdater = async () => {
  const response = await axiosClient.post('/nfl/start-periodic-update')
  return response.data
}

export const stopNFLUpdater = async () => {
  const response = await axiosClient.post('/nfl/stop-periodic-update')
  return response.data
}

export const ingestNFLData = async () => {
  const response = await axiosClient.post('/nfl/ingest-nfl-data')
  return response.data
}

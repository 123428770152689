import React, { useEffect, useState } from 'react'
import { DraftRoster } from '../../DraftRoster'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { DateFormatDay, isTeamAlreadyNominated } from '../../../../../../helpers/utils'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedTeam, setTeamEnqueued } from '../../../../../../store/slices/draftSlice'
import { nominateTeamDraft } from '../../../../../../api/services/draft'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

function SummaryTab() {

  const dispatch = useDispatch()

  const { manager, draft } = useSelector((state) => state)
  const { selectedTeam } = useSelector((state) => state.draft)
  const [myManager, setMyManager] = useState(null)

  useEffect(() => {
    const myManagerInfo = draft.managers.find(
      (draftManager) => draftManager.id === manager.id
    )
    setMyManager(myManagerInfo)
  }, [manager])

  const isRosterFull = () => {
    if (selectedTeam.team.is_offense && myManager?.offense_teams.length == 2) {
      return true
    }
    if (!selectedTeam.team.is_offense && myManager?.defense_teams.length == 2) {
      return true
    }
    return false
  }

  const unselectTeam = () => {
    dispatch(setSelectedTeam(null))
  }

  const nominateButtonHandler = (team) => {
    if (team && isTeamAlreadyNominated(team)) {
      return (
        <Button variant="outlined" sx={{ margin: '10px 0', width: '100%', textTransform: 'none' }}
          disabled={true}>
          Nominated
        </Button>
      )
    }

    return (
      <div>
        <Button
          variant="outlined"
          sx={{ margin: '10px 0', width: '100%', textTransform: 'none' }}
          id='draftNominateTeamBtn'
          onClick={() => nominateTeamHandler()}
          disabled={!team || isNominateTeamLoading || isNominateTeamFetching || isRosterFull() || !isManagerTurn()}
        >
          Nominate Team
        </Button>
        {team && isRosterFull() && (
          <Typography variant="body2" color="error" textAlign='center'>
            Your {team.is_offense ? 'offensive' : 'defensive'} roster slots are full.
          </Typography>
        )}
      </div>
    )
  }

  const {
    mutate: nominateTeam,
    isLoading: isNominateTeamLoading,
    isFetching: isNominateTeamFetching,
  } = useMutation(() => nominateTeamDraft(draft.selectedTeam.team.id), {
    onSuccess: () => {
    },
    onError: (error) => {
      toast.error(error?.response.data.message)
    },
  })

  // Checks if the current nominating manager it's me
  const isManagerTurn = () => {
    return manager.id === draft.draftStatus.nominating_manager
      && draft.draftStatus.period == 'nominating'
      && draft.draftStatus.status == 'in_progress'
  }

  // Select team that will be nominated
  const nominateTeamHandler = () => {
    dispatch(setTeamEnqueued())
    if (isManagerTurn()) {
      nominateTeam()
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" width="100%" justifyContent="space-between">
        <DraftRoster />
        <Box
          border="1px solid lightgray" borderRadius="5px" position="relative" flexGrow={1}
          padding={0.5} display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
          {
            selectedTeam ?
              <>
                <IconButton
                  aria-label="close"
                  size="small"
                  id='unselectTeamTabBtn'
                  onClick={unselectTeam}
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                >
                  <CloseIcon />
                </IconButton>
                {/* Owned team */}
                <Typography whiteSpace='nowrap' marginTop={0} component="span" variant='h6'>
                  <span style={{ fontWeight: 'bold' }}>
                    {selectedTeam.team.is_offense ? 'Off' : 'Def'}{selectedTeam.team.name}
                  </span>
                </Typography>
                {/* Next matchup */}
                <Typography whiteSpace="nowrap" marginTop={0} variant='h6' fontSize="0.90rem">
                  @{selectedTeam.team.is_offense ? 'Def' : 'Off'}-{selectedTeam.opponent.abbreviation} | {DateFormatDay(selectedTeam.matchup_date)}
                </Typography>
              </> :
              <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: 'lightgray', textAlign: 'center' }} variant='h6'>
                No Team Added to Queue
              </Typography>
          }
        </Box>
      </Box>
      <Box width='100%' display='flex' alignItems='center'
        justifyContent='space-between' flexDirection='row'>
        <Box display='flex' justifyContent='center'
          sx={{ marginRight: '10px', padding: '5px', width: '131px' }} width='100%'>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
            My Roster
          </Typography>
        </Box>
        <Box flexGrow={1}>
          {nominateButtonHandler(selectedTeam?.team)}
        </Box>
      </Box>
    </Box>
  )
}

export default SummaryTab
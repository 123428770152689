import React, { useState, useEffect } from 'react'
import {
  Button, LinearProgress, Paper, IconButton,
  Collapse,
  Divider
} from '@mui/material'
import { Box } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import CustomizedSnackbar from '../../../../components/common/CustomizedSnackbar'
import AlertDialog from '../../../../components/common/AlertDialog'
import {
  blockTeamAttempt,
  cancelTransaction,
  filibusterTeamAttempt,
  sellTeamAttempt,
} from '../../../../api/services/steal'
import TransactionDetails from '../../TransactionDetails'
import { formatCurrency } from '../../../../helpers/utils'
import TeamInfo from '../../../../components/TeamInfo'

export const StealCard = ({ transaction, refetchAllTransactions, received = true }) => {
  const [showButtons, setShowButtons] = useState(false)
  const [open, setOpen] = useState(false)
  const { mutateAsync, isError, error, isSuccess } = useMutation(
    [`cancel_transaction_${transaction.id}`],
    () => cancelTransaction(transaction.id)
  )

  const deleteTransactionHandler = () => {
    mutateAsync().then(() => setTimeout(refetchAllTransactions(), 2000))
    setOpen(false)
  }
  const next_matchup = transaction.next_matchup

  const {
    mutate: blockMutate,
    isError: isBlockError,
    isSuccess: isBlockSuccess,
    isLoading: isBlockLoading,
    error: errorBlock,
  } = useMutation(['block_attempt'], () => blockTeamAttempt(transaction.id), {
    retry: false,
  })

  const {
    mutate: sellMutate,
    isError: isSellError,
    isSuccess: isSellSuccess,
    isLoading: isSellLoading,
    error: errorSell,
  } = useMutation(['sell_team'], () => sellTeamAttempt(transaction.id), {
    retry: false,
  })

  const {
    mutate: filibusterMutate,
    isError: isFilibusterError,
    isSuccess: isFilibusterSuccess,
    isLoading: isFilibusterLoading,
    error: errorFilibuster,
  } = useMutation(['filibuster_team'], () => filibusterTeamAttempt(transaction.id), {
    retry: false,
  })

  useEffect(() => {
    if (isBlockSuccess || isSellSuccess || isFilibusterSuccess) {
      setTimeout(() => refetchAllTransactions(), 1000)
    }
  }, [isBlockSuccess, isSellSuccess, isFilibusterSuccess])

  const handleToggleButtons = () => {
    setShowButtons(prev => !prev)
  }

  return (
    <Box marginBottom="20px">
      <AlertDialog
        open={open}
        setOpen={setOpen}
        handleDelete={deleteTransactionHandler}
      />
      <CustomizedSnackbar
        isError={isError}
        errorMessage={error?.response.data.detail}
        isSuccess={isSuccess}
        successMessage={'Transaction deleted!'}
      />
      <CustomizedSnackbar
        errorMessage={errorBlock?.response.data.message}
        isError={isBlockError}
        isSuccess={isBlockSuccess}
      />
      <CustomizedSnackbar
        errorMessage={errorSell?.response.data.detail}
        isError={isSellError}
        isSuccess={isSellSuccess}
      />
      <CustomizedSnackbar
        errorMessage={errorFilibuster?.response.data.message}
        isError={isFilibusterError}
        isSuccess={isFilibusterSuccess}
      />
      <Paper elevation={1}>
        <Box display="flex" flexDirection="column" alignItems="center">

          <TeamInfo transaction={transaction} next_matchup={next_matchup} />

          <Divider sx={{ width: '100%' }} />

          <TransactionDetails transaction={transaction} next_matchup={next_matchup} received={received} />

          {isBlockLoading || isSellLoading || isFilibusterLoading ? (
            <LinearProgress />
          ) : (
            <Collapse in={showButtons}>
              {
                received ?
                  <Box width="100%" display="flex" justifyContent="space-evenly" padding="16px 3px 0px 6px">
                    <Button
                      id='bidOptionsBidBtn'
                      variant="outlined"
                      onClick={blockMutate}
                      sx={{ flexGrow: 1, fontSize: '0.70rem', marginX: '2px', padding: '10px 6px', fontWeight: 'bold' }}
                    >
                      Match Bid <span style={{ color: 'red', marginLeft: 4 }}>({formatCurrency(transaction.team_to_steal_value - transaction.bid)})</span>
                    </Button>

                    <Button
                      id='bidOptionsSellBtn'
                      variant="outlined"
                      onClick={sellMutate}
                      sx={{ flexGrow: 1, fontSize: '0.70rem', marginX: '2px', padding: '10px 6px', fontWeight: 'bold' }}
                    >
                      Sell team <span style={{ color: 'green', marginLeft: 4 }}>(+{formatCurrency(transaction.bid)})</span>
                    </Button>

                    <Button
                      id='bidOptionsFilibusterBtn'
                      variant="outlined"
                      onClick={filibusterMutate}
                      sx={{ flexGrow: 1, fontSize: '0.70rem', marginX: '2px', padding: '10px 6px', fontWeight: 'bold' }}
                    >
                      Filibuster <span style={{ color: 'green', marginLeft: 4 }}>(+{formatCurrency(transaction.bid)})</span>
                    </Button>
                  </Box> :
                  <Box width="100%" display="flex" justifyContent="space-evenly" padding="10px 5px">
                    <Button
                      id='CancelBidBtn'
                      variant="outlined"
                      color="error"
                      onClick={() => setOpen(true)}
                      sx={{ fontSize: '0.75rem', padding: '6px 12px' }}
                    >
                      Cancel Bid
                    </Button>
                  </Box>
              }

            </Collapse>
          )}

          <IconButton onClick={handleToggleButtons} sx={{ margin: 0, padding: 0.5 }}>
            {showButtons ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Paper>
    </Box>
  )
}

import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'

import { CenterContainer } from '../../../styles'
import TeamPreview from './TeamPreview'

export const DraftCard = ({
  cardData,
  cardMetaData,
  teamEnqueued,
  isSelectedTeam,
  isDesktop,
  unselectTeam,
  bestBidder
}) => {
  const { team } = cardData


  return (
    <Box margin="2px 0 2px 0" minWidth={isDesktop ? '250px' : 0}>
      {!teamEnqueued && (
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', top: 0, right: 0 }}
          id='unselectTeamBtn'
          onClick={unselectTeam}
        >
          {isSelectedTeam && <CloseIcon color="primary" />}
        </IconButton>
      )}
      <CenterContainer width="100%">
        <TeamPreview next_matchup={cardData}
          transaction={{
            team_to_steal_name: cardData.team.name,
            kind: cardData.team.is_offense ? 'offense' : 'defense',
            team_to_steal_value: cardMetaData?.best_bid
          }} />
        <Divider sx={{ width: '90%' }} />
        <Box>
        </Box>

        <Box padding={1} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', whiteSpace: 'nowrap' }}>
          <Typography marginTop={0} variant='h6' fontSize="0.90rem">
            Top Bidder: {bestBidder.substring(0, 12)}
          </Typography>
          <Typography marginTop={0} variant='h6' fontSize="0.90rem">
            Cap Rate: {(team.projected_points / (cardMetaData?.best_bid || 1)).toFixed(2)}x
          </Typography>
        </Box>

      </CenterContainer>

    </Box>
  )
}

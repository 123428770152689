import styled from '@emotion/styled'

export default styled.div`
  background-color: white;
  background-position: center;
  border-radius: 10px;
  flex-direction: column;
  padding: 15px 0;
  z-index: 99;

  @media (min-width: 900px) {
    flex-direction: row;
  }
`

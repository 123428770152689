import { Button, Container, Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Text } from '../../../styles/global'
import { useDispatch, useSelector } from 'react-redux'
import { setType } from '../../../store/slices/stealSlice'

export const MyCashLeft = ({ managerData, leagueUpdatedDataFetching }) => {
  const dispatch = useDispatch()
  const { type } = useSelector((state) => state.steal)

  const handleClick = () => {
      dispatch(setType('offenses'))
  }

  return (
    <Box
      bgcolor="white"
      zIndex="1"
      left="0"
      width="100%"
      padding="10px 0 5px 0"
    >
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {!leagueUpdatedDataFetching ? (
            <Text variant="h6" hasprice component="span" color="black" maintainsize={true}>
              {managerData?.name} <span>${managerData?.cash_remaining}</span>
            </Text>
          ) : (
            <Skeleton
              variant="rectangular"
              width="150px"
              height={25}
            />
          )}
          {type !== null ? (
            <Button
              id='backToRoster'
              onClick={() => dispatch(setType(null))}
              sx={{
                color: 'gray',
                textDecoration: 'underline !important',
                textTransform: 'none',
                fontWeight: 'bold'
              }}
            >
              {'< Back to Roster'}
            </Button>
          ) : (
            <Button
            id='editRoster'
              onClick={handleClick}
              sx={{
                color: 'gray',
                textDecoration: 'underline !important',
                textTransform: 'none',
                fontWeight: 'bold'
              }}
            >
              Edit Roster +
            </Button>
          )}
        </Box>
        {type !== null && (
          <Box marginTop="10px" display="flex" justifyContent="center">
            <Button
              id='avalibleOffenses'
              onClick={() => dispatch(setType('offenses'))}
              sx={{
                color: type === 'offenses' ? 'black' : 'gray',
                marginRight: '30px',
                fontSize: 16
              }}
            >
              Offenses
            </Button>
            <Button
              id='avalibleDefenses'
              onClick={() => dispatch(setType('defenses'))}
              sx={{
                color: type === 'defenses' ? 'black' : 'gray',
                fontSize: 16
              }}
            >
              Defenses
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  )
}

import React, { useRef } from 'react'

import { useSelector } from 'react-redux'
import { useEffect } from 'react'
// import { teamPics } from '../../../../../../helpers/utils'
import { Text } from '../../../../../../styles/global'
import { Box } from '@mui/system'
import { formatNumberToOneDecimal } from '../../../../../../helpers/utils'

export const ScheduleTeam = ({ match, teamIsOffense }) => {
  const myRef = useRef(null)
  const season = useSelector((state) => state?.user.season)
  const { current_week } = season
  const { opponent } = match

  useEffect(() => {
    if (myRef.current != null) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [])

  const opponentPrefix = teamIsOffense ? 'DEF-' : 'OFF-'

  return (
    <Box display="flex" paddingBottom="10px">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        marginRight="10px"
        sx={{
          width: '75px',
          '@media (min-width: 768px)': {
            width: '100px'
          }
        }}
        margin="0 7px"
      >
        {/* <Box
          component="img"
          src={teamPics[opponent.abbreviation]}
          width="30px"
          height="30px"
        /> */}

        <Box display="flex" alignItems="center" gap="2px">
          <Text variant="caption">
            {opponent.location === 'away' && '@'}
          </Text>
          <Text variant="caption">
            {`${opponentPrefix}${opponent.abbreviation}`}
          </Text>
        </Box>
        <Text variant="subtitle2">
          {match.result
            ? `${match.result} ${match.team_score}-${match.opponent_score}`
            : '-'}
        </Text>

        <Text variant="caption">
          Proj: {match.projected_points ? formatNumberToOneDecimal(match.projected_points) : '-'}
        </Text>

        <Text
          ref={match.week === current_week ? myRef : null}
          variant="caption"
          sx={{
            color: 'gray',
            textDecoration: match.week === current_week ? 'underline' : null,
          }}
        >
          Week {match.week}
        </Text>
      </Box>
    </Box>
  )
}

import { Container, Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Navbar from '../../Navbar'

export const MatchupsSkeleton = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Box margin="10px">
          <Skeleton variant="rectangular" width="100%" height={50} />
          <Box display="flex" justifyContent="space-between" margin="20px 0">
            <Skeleton variant="rectangular" width="48%" height={500} />
            <Skeleton variant="rectangular" width="48%" height={500} />
          </Box>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Box>
      </Container>
    </>
  )
}

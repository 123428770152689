import styled from '@emotion/styled'

export default styled.video`
  height: 100vh;
  left: -365;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: auto;

  @media (min-width: 900px) {
    height: auto;
    left: 0;
    width: 100vw;
  }
`

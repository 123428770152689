import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DraftRosterContainer } from '../../../styles'
import { DraftRosterItem } from './DraftRosterItem'

export const DraftRoster = () => {
  const { manager, draft } = useSelector((state) => state)
  const [myManager, setMyManager] = useState(null)

  useEffect(() => {
    const myManagerInfo = draft.managers.find(
      (draftManager) => draftManager.id === manager.id
    )
    setMyManager(myManagerInfo)
  }, [manager, draft.managers])

  return (
    <DraftRosterContainer>
      <Box display="flex"
        sx={{ width: '130px', paddingX: '10px' }}
        borderBottom="1px solid lightgray" borderTop="1px solid lightgray"
        flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" justifyContent='space-between'>
          <Box>
            <DraftRosterItem myTeam={myManager?.offense_teams[0]} />
          </Box>
          <Box sx={{ marginLeft: '4px' }}>
            <DraftRosterItem myTeam={myManager?.defense_teams[0]} />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent='space-between'>
          <Box>
            <DraftRosterItem myTeam={myManager?.offense_teams[1]} />
          </Box>
          <Box sx={{ marginLeft: '4px' }}>
            <DraftRosterItem myTeam={myManager?.defense_teams[1]} />
          </Box>
        </Box>
      </Box>
    </DraftRosterContainer >
  )
}

import { axiosClient } from '../axios'

export const createOrJoinLeague = async (data, type) => {
  var league = await axiosClient.post(
    `/auth/users/me/${type === 'create' ? 'create-league' : 'join-league'}`,
    data
  )
  return league
}

export const getAllLeagues = async () => {
  var leagues = await axiosClient.get('/leagues/leagues')
  return leagues.data
}

export const deleteLeague = async (leagueId) => {
  var leagueData = await axiosClient.delete(
    `/leagues/managers/${leagueId}/delete-league`
  )
  return leagueData.data
}

export const generateLeaguePassword = async () => {
  var password = await axiosClient.get('/leagues/generate-password')
  return password
}

export const getLeagueInfoByPassword = async (password) => {
  var leagueData = await axiosClient.get(`/leagues/leagues/${password}?is_password=true`)
  return leagueData.data
}

export const getLeagueInfo = async (leagueId) => {
  var leagueData = await axiosClient.get(`/leagues/leagues/${leagueId}/info`)
  return leagueData.data
}

export const getLeagueTransactions = async (leagueId) => {
  var transactionData = await axiosClient.get(
    `/steals/league-transactions/${leagueId}`
  )
  return transactionData.data
}

export const cancelLeagueTransactions = async (transactionId) => {
  var transactionData = await axiosClient.patch(
    `/steals/cancel-steal-attempt/${transactionId}`
  )
  return transactionData.data
}

export const lockLeague = async (managerId) => {
  var transactionData = await axiosClient.patch(
    `/leagues/managers/${managerId}/lock-league`
  )
  return transactionData.data
}

export const getLeagueManager = async (managerId) => {
  var managerData = await axiosClient.get(`/leagues/managers/${managerId}`)
  return managerData.data
}

export const getLeagueMatchups = async (leagueId, week) => {
  var managerData = await axiosClient.get(
    `/leagues/leagues/${leagueId}/matchups/${week}`
  )
  return managerData.data
}

export const deleteManager = async (managerId) => {
  var managerData = await axiosClient.delete(
    `/leagues/managers/${managerId}/delete-manager`
  )
  return managerData.data
}

import { Button } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Text } from '../../styles/global'

export const PageNotFound = () => {
  const navigate = useNavigate()
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="20px"
      >
        <Box
          width="100%"
          component="img"
          src="/assets/images/captrade-logo.png"
        />
        <Text
          maintainsize
          variant="h2"
          sx={{ color: 'primary.main' }}
          margin="20px 0 40px 0"
        >
          Page not found
        </Text>
        <Button variant="outlined" onClick={() => navigate('/')} fullWidth>
          Go back
        </Button>
      </Box>
    </Container>
  )
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // Select team
  selectedTeam: null,
  teamEnqueued: false,
  isDraftUpdate: false,
  // API data
  managers: [],
  availableTeams: {
    defenses: [],
    offenses: [],
  },
  draftStatus: {
    auctioneer: null,
    id: null,
    is_active: false,
    league: null,
    nominating_manager: null,
    nomination_deadline: null,
    nomination_round: null,
    period: '',
    status: '',
  },
  // All available teams that were nominated by a manager
  nominatedTeams: [],

  endIsAllowed: false, // Not in use
  onlineManagers: [],
  auction: {
    best_bid: null,
    best_bidder: null,
    draft: null,
    ending_time: null,
    id: null,
    is_active: false,
    team_auctioned: null,
  },
  clockSkew: null, // Difference between server time and client time
}

export const draftSlice = createSlice({
  name: 'draft',
  initialState: initialState,
  reducers: {
    setSelectedTeam: (state, action) => {
      state.selectedTeam = action.payload
    },
    setTeamEnqueued: (state) => {
      state.teamEnqueued = !state.teamEnqueued
    },
    setDraftStatus: (state, action) => {
      state.draftStatus = action.payload.draft
      state.endIsAllowed = action.payload.end_is_allowed
      if (action.payload.last_auction?.is_active) {
        state.auction = action.payload.last_auction
      } else {
        state.auction = {
          best_bid: null,
          best_bidder: null,
          draft: null,
          ending_time: null,
          id: null,
          is_active: false,
          team_auctioned: null,
        }
      }
    },
    setDraftManagers: (state, action) => {
      state.managers = action.payload
    },
    setDraftAvailableTeams: (state, action) => {
      state.availableTeams = action.payload
    },
    setDraftManagersOnlineList: (state, action) => {
      state.onlineManagers = action.payload
    },

    resetDraftAuction: (state) => {
      state.auction = {
        best_bid: null,
        best_bidder: null,
        draft: null,
        ending_time: null,
        id: null,
        is_active: false,
        team_auctioned: null,
      }
    },
    setDraft: (state, action) => {
      state.draftStatus = action.payload.draft
      state.managers = action.payload.managers
      state.availableTeams = action.payload.available_teams
      state.isDraftUpdate = true
      state.endIsAllowed = action.payload.end_is_allowed
    },
    setClockSkew: (state, action) => {
      state.clockSkew = action.payload
    },
    // Reset
    resetDraft: () => initialState,
  },
})

export const {
  setSelectedTeam,
  setTeamEnqueued,
  setDraftStatus,
  setDraftManagers,
  setDraftAvailableTeams,
  setDraftManagersOnlineList,
  setDraft,
  resetDraftAuction,
  resetDraft,
  setClockSkew,
} = draftSlice.actions

export default draftSlice.reducer

import React from 'react'

import football from '../../assets/videos/football-field.mp4'
import footballImg from '../../assets/images/football-field.png'
import Login from './Login'
import Video from './Video'
import { Box, Hidden } from '@mui/material'

const myWindow = {
  style: {
    backgroundColor: 'rgba(108, 154, 91, 0.6)',
    overflow: 'auto',
    height: '100vh',
  },
  class: 'container-fluid text-center d-flex flex-column align-items-start p-0',
}

const overlayStyle = {
  position: 'fixed',
  top: 0,
  minHeight: '100vh',
  minWidth: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
}

function Landing() {
  return (
    <div style={myWindow.style} className={myWindow.class}>
      <Hidden mdDown>
        <Video autoPlay loop muted>
          <source src={football} type="video/mp4" />
        </Video>
        <Login />
        <div
          className="overlay d-flex justify-content-start text-start flex-column aligin-items- text-light"
          style={overlayStyle}
        ></div>
      </Hidden>
      <Hidden mdUp>
        <Box
          style={{
            backgroundImage: `url(${footballImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            width: '100%',
          }}
        >
          <Box maxWidth="320px" margin="auto">
            <Login />
          </Box>
        </Box>
      </Hidden>
    </div>
  )
}

export default Landing

import React from 'react'
import { Box, Typography } from '@mui/material'
import moment from 'moment'

const TransactionDetails = ({ transaction, next_matchup, received }) => {
  return (
    <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" marginTop={1} paddingX={1}>
      <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
        <Typography variant="subtitle2" component="span">
          Bid <span style={{ color: 'green' }}>${transaction.bid}</span>
        </Typography>
        <Typography variant="subtitle2" component="span" fontSize={'0.85em'}>
          Implied Cap Rate: {(next_matchup.team.projected_points / transaction.bid).toFixed(2)}x
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
        <Typography variant="subtitle2" fontSize={'0.85em'}>
          Will Process: {moment(transaction.scheduled_for).format('ddd h:mm A')}
        </Typography>
        {
          received
          ? <Typography variant="subtitle2" fontSize={'0.85em'}>
              Attempt by: {transaction.claimant_name}
            </Typography>
          : <Typography variant="subtitle2" fontSize={'0.85em'}>
              Team to Drop: {transaction.kind == 'offense' ? 'Off' : 'Def'}-{transaction.team_to_drop_abbreviation}
              <span style={{color: 'green'}}> ${transaction.team_to_drop_value}</span>
            </Typography>
        }
      </Box>
    </Box>
  )
}

export default TransactionDetails

import { Box } from '@mui/material'
import React from 'react'
import { MatchCard } from './MatchCard'

export const MatchArea = ({ matchup, sortByKind }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="start"
      marginBottom="10px"
    >
      <MatchCard matchupTeam={sortByKind(matchup[0])} />
      -
      <MatchCard matchupTeam={sortByKind(matchup[1])} reverse />
    </Box>
  )
}
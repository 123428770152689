import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material'
import { DraftCard } from './DraftCard'
import { messages } from '../../../../messages'
import { useSelector } from 'react-redux'
import { NoBiddingTeamCard } from './NoBiddingTeamCard'
import { DraftHead } from './DraftHead'
import { DraftBody } from '../../styles'
import DraftTabs from './DraftTabs'
import { useMutation } from '@tanstack/react-query'
import { bidOnTeam } from '../../../../api/services/draft'
import { toast } from 'react-toastify'

export const DraftArea = ({
  draftOnlyRefetch,
  draftManagersRefetch,
  draftavailableTeamsRefetch
}) => {
  const { manager, draft } = useSelector((state) => state)
  const [myManager, setMyManager] = useState(null)

  const { auction, availableTeams } =
    useSelector((state) => state.draft)

  useEffect(() => {
    const myManagerInfo = draft.managers.find(
      (draftManager) => draftManager.id === manager.id
    )
    setMyManager(myManagerInfo)
  }, [manager])

  const teamData = useMemo(() => {
    const team = availableTeams.offenses.find(
      (teamData) => teamData.team.id === auction.team_auctioned
    )

    if (!team) {
      const teamDef = availableTeams.defenses.find(
        (teamData) => teamData.team.id === auction.team_auctioned
      )

      return teamDef
    }

    return team
  }, [auction, availableTeams, draft])

  const {
    mutate: bidTeam,
    isLoading,
  } = useMutation(
    ['bid_on_nominating_team'],
    (bidValue) => bidOnTeam(auction.best_bid + bidValue, auction.id),
    {
      onError: (error) => {
        toast.error(error?.response.data.message)
      },
    }
  )

  const makeBidHandler = (bidValue) => {
    bidTeam(bidValue)
  }

  const isRosterFull = () => {
    if (teamData && teamData.team.is_offense && myManager?.offense_teams.length == 2) {
      return true
    }
    if (teamData && !teamData.team.is_offense && myManager?.defense_teams.length == 2) {
      return true
    }
    return false
  }

  const managersFreeSlots = () => {
    let total_teams = manager.offense_teams
      ? manager.offense_teams.length - manager.defense_teams.length
      : 0
    return 4 - total_teams
  }

  const isBidNotAllowed = (bid_amount) => {
    const freeSlots = managersFreeSlots()

    if (manager.id === draft.auction.best_bidder
      || draft.draftStatus.status === 'paused'
      || manager.cash_remaining < bid_amount + freeSlots - 1
      || freeSlots === 0) {
      return true
    }
    return false
  }

  const getBestBidderName = (auction, draft) => {
    if (!auction || !draft || !draft.managers) return null

    const bestBidderId = auction.best_bidder
    const bestBidder = draft.managers.find(manager => manager.id === bestBidderId)

    return bestBidder ? bestBidder.name : null
  }

  const getTooltipTitle = () => {
    if (!teamData) return ''
    if (isRosterFull()) {
      return `Your ${teamData.team.is_offense ? 'offensive' : 'defensive'} roster slots are full.`
    }
    if (isBidNotAllowed(1)) {
      return ''
    }
    return ''
  }

  return (
    <>
      <DraftHead
        draftOnlyRefetch={draftOnlyRefetch}
        draftManagersRefetch={draftManagersRefetch}
        draftavailableTeamsRefetch={draftavailableTeamsRefetch}
      />
      <DraftBody>
        <Box display='flex'
          alignItems="end"
          justifyContent="space-between" width="100%">
          <Typography
            whiteSpace="nowrap"
            variant="h6" sx={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}>
            {messages[9].biddingOn}:
          </Typography>
          <Box>
            {isLoading ? (
              <Box display="flex" justifyContent="space-around" padding="5px">
                <Skeleton variant="rectangular" height="35px" width="65px" />
              </Box>
            ) : (
              <Box display="flex" justifyContent="space-around" paddingBottom="5px">
                <Tooltip title={getTooltipTitle()} arrow disableHoverListener={!(!teamData || isBidNotAllowed(1) || isRosterFull())}>
                  <span>
                    <Button
                      id="draftBidBtn"
                      onClick={() => makeBidHandler(1)}
                      variant="outlined"
                      sx={{ minWidth: '140px', textTransform: 'none', fontWeight: 'bold', lineHeight: '18px', paddingTop: '10px' }}
                      disabled={!teamData || isBidNotAllowed(1) || isRosterFull()}
                    >
                      {teamData ? `Bid $${((auction?.best_bid ?? 0) + 1) || 1}` : 'Bid'}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          borderRadius="5px"
          border="1px solid lightgray">
          {auction.team_auctioned && teamData ? (
            <DraftCard
              cardData={teamData}
              cardMetaData={auction}
              draftOnlyRefetch={draftOnlyRefetch}
              bestBidder={getBestBidderName(auction, draft)}
            />
          ) : (
            <NoBiddingTeamCard />
          )}

          {/* {selectedTeam && (
            <>
              <Typography variant="h5" color="primary.main">
                {messages[9].selectedTeam}
              </Typography>
              <DraftCard
                cardData={selectedTeam}
                teamEnqueued={teamEnqueued}
                unselectTeam={unselectTeam}
                isSelectedTeam
                draftOnlyRefetch={draftOnlyRefetch}
              />
            </>
          )} */}
        </Box>
        <DraftTabs />
      </DraftBody>
    </>
  )
}

import * as React from 'react'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import { messages } from '../../../../messages'

export default function TooltipDialog() {

  return (
    <>
      <Button
        id='bidOptionsHelpBtn'
        data-testid="help-text-btn"
        startIcon={<InfoIcon />}
        sx={{
          padding: 0,
          textTransform: 'none',
          color: 'black',
          textDecoration: 'underline',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline'
          }
        }}
      >
        {messages[3].tooltipTitle}
      </Button>
    </>
  )
}

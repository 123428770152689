const moment = require('moment')

const utcTime = moment.utc('08:00', 'HH:mm')
const localTime = utcTime.local().format('ha')

export const messages = [
  {
    defaultError: 'Error! Please, contact administrator',
    defaultSuccess: 'Success! Your action has been sent.',
  },
  {
    noScheduleFound: 'Could not find a schedule for this team!',
  },
  {
    welcomeMessage: 'Welcome',
    JoinFirstLeagueMessage:
      'Create a new league or join an existing one to get started.',
    JoinAnyLeagueMessage: 'Start New League',
    StartNewMockDraft: 'Start New Mock Draft',
    JoinLeague: 'Join League',
    JoinMockDraft: 'Join Mock Draft',
    createdLeagueMessage: 'League created and password copied to clipboard!',
    joinLeagueMessage: 'League joined!',
  },
  {
    stealAttemptsMessage: 'Pending Bids',
    stealAttemptsDialog: 'All Bids',
    cashRemainingMessage: 'Cash Remaining',
    stealReceivedMessage: 'Received Bids',
    tooltipTitle: 'Match bid, sell team and filibuster',
  },
  {
    noAvailableTeamError: 'No available teams yet',
    noTeamsSelectedError: 'No teams selected yet',
  },
  {
    OtherLeagueMatchups: 'Other league matchups',
  },
  {
    noTeamSelected: 'No team selected',
    noMatchUps: 'No matchups yet',
    notLocked: 'league not yet locked',
  },
  {
    tooltipMatchBid:
      'As the current manager of a team, you can block a bid attempt by matching the buying manager’s bid to keep a team on your roster. A matched bid will be effective immediately. You will invest the cash required to match the bid, the team’s market value will reset to the bid amount, and the bid attempt will fail. The bidding manager will receive a notification and will have an opportunity to increase his or her bid.',
    tooltipSellTeam:
      'Selling a team will allow you to profit off of the increased value of a team on your roster. When a manager in your league bids on one of your teams, you can immediately sell the team at that price. You will receive cash for that team equal to the new manager’s bid, the team’s market value will increase to the bid amount, and the team will be transferred to the new manager.',
    tooltipFilibuster:
      'Filibustering allows you to hold onto a team until the next processing window for this team by forgoing a chance to earn a profit. When a manager in your league bids on one of your teams, you can wait them out by clicking filibuster. This will signal to the manager bidding on your team that you are unwilling to match their bid, but if they want you to immediately sell the team, they will need to increase their bid. You will not profit from a filibuster. When you click filibuster, the bidding manager will receive a notification that you plan to wait them out. If the bidding manager does not respond with a higher bid, the bid will eventually transact during the next processing window. If the transaction reaches the processing window, you will receive the current market value of the team in cash, the team will be transferred to the bidding manager’s roster, and the team’s market value will then increase to the new manager’s bid amount.',
  },
  {
    retryClaimed:
      `This team's matchup starts before this transaction's processing date. If this team's owner does not respond before the matchup starts, this team will not score points for your roster this week but the steal attempt will remain and will process Wednesday at ${localTime}. Would you like to continue?`,
    retryUnownedClaimed:
      'The team is playing/has played. You cannot pick this team until next week. If you continue, you may be competing against other stealing managers, the best bid will get the team right after the last matchup ends',
    duplicatedClaimed:
      'You already sent a steal attempt to this team. If you continue, this will replace the previous attempt',
  },
  {
    managersRoster: 'Managers Roster',
    chooseTeam: 'Choose a Team',
    biddingOn: 'Bidding On',
    selectedTeam: 'Selected Team',
    noBidTeam: 'No bidding team yet!',
  },
  {
    enterDraft: 'Enter draft room',
    leaveDraft: 'Leave draft room',
  },
  {
    confirmMessage: 'Are you sure you want to delete this?',
  },
  {
    timeRemaining: 'Time Remaining',
    startDraft: 'Start draft',
    endDraft: 'End draft',
    resumeDraft: 'Resume draft',
    pauseDraft: 'Pause draft',
    offense: 'Offense',
    defense: 'Defense',
    consentToEndDraft: 'Are you sure you would like to end the draft? This action is not reversible'
  },
  {
    availableTeams: 'Available Teams'
  },
  {
    manage: 'Manage',
    bid: 'Bid',
  },
  {
    emptyTeamSlot: 'Empty team slot',
  },
]

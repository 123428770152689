import * as yup from 'yup'

// If user typed in password field, league name isn't required. Vice versa.
export const joinOrCreateLeagueSchema = yup.object().shape({
  name: yup
    .string()
    .max(32, 'Must be at most 32 characters')
    .when(
      ('password',
      {
        is: (password) => password?.length === 0,
        then: yup.string().required('League name is a required field'),
      })
    ),
  manager_name: yup
    .string()
    .max(12, 'Must be at most 12 characters')
    .required('Team name is a required field'),
  password: yup.string().when(
    ('name',
    {
      is: (name) => name?.length === 0,
      then: yup.string().required('League password is a required field'),
    })
  ),
})

import { Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Navbar from '../../../components/Navbar'
import LeagueTable from '../components/LeagueTable'
//import LeagueTransactions from '../components/LeagueTransactions'

export const LeagueDetail = () => {
  const league = useSelector((state) => state.league)

  return (
    <>
      <Navbar />
      <Container maxWidth="md">
        <LeagueTable leagueData={league} />

        {/* {
          league && !league.is_mock &&
          <LeagueTransactions leagueId={league.id} />
        } */}

      </Container>
    </>
  )
}

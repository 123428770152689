import { createSlice } from '@reduxjs/toolkit'

const initialState = { name: '', id: 0, cash_remaining: 100 }
export const managerSlice = createSlice({
  name: 'manager',
  initialState: initialState,
  reducers: {
    setManager: (state, action) => {
      return action.payload
    },
    setUpdateManagerCash: (state, action) => {
      state.cash_remaining = action.payload
    },
    resetManager: () => initialState,
  },
})

export const { setManager, resetManager, setUpdateManagerCash } =
  managerSlice.actions

export default managerSlice.reducer

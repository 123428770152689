import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, FormControlLabel, Switch, Typography, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import { setSelectedTeam, setTeamEnqueued } from '../../../../../../store/slices/draftSlice'
import TeamPreview from '../../DraftCard/TeamPreview'
import { isTeamAlreadyNominated } from '../../../../../../helpers/utils'

function TeamsTab({ setSelectedTab }) {
  const [isOffense, setIsOffense] = useState(false)
  const dispatch = useDispatch()
  const { manager } = useSelector((state) => state)
  const { availableTeams, selectedTeam, teamEnqueued, managers } = useSelector((state) => state.draft)
  const [myManager, setMyManager] = useState(null)
  const [teamsState, setTeamsState] = useState(availableTeams.defenses)
  const [sortType, setSortType] = useState(null)

  const sortAlphabetically = (teams) =>
    teams.slice().sort((a, b) => a.team.name.localeCompare(b.team.name))

  const sortByProjectedPoints = (teams) =>
    teams.slice().sort((a, b) => b.team.projected_points - a.team.projected_points)

  const updateTeamsState = (teams) => {
    let updatedTeams = teams
    if (sortType === 'alphabetical') {
      updatedTeams = sortAlphabetically(teams)
    } else if (sortType === 'projectedPoints') {
      updatedTeams = sortByProjectedPoints(teams)
    }
    setTeamsState(updatedTeams)
  }

  useEffect(() => {
    const myManagerInfo = managers.find(
      (draftManager) => draftManager.id === manager.id
    )
    setMyManager(myManagerInfo)
  }, [manager, managers])

  useEffect(() => {
    const teams = isOffense ? availableTeams.offenses : availableTeams.defenses
    updateTeamsState(teams)
  }, [availableTeams, isOffense, sortType])

  const handleSwitchChange = () => {
    setIsOffense((prev) => !prev)
    setSortType(null)
  }

  const sortAlphabeticallyHandler = () => {
    setSortType('alphabetical')
  }

  const sortProjPointsHandler = () => {
    setSortType('projectedPoints')
  }

  const selectTeam = (teamData) => {
    dispatch(setSelectedTeam(teamData))
    if (teamEnqueued && teamData.team.id !== selectedTeam.team.id) {
      dispatch(setTeamEnqueued())
    }
  }

  const selectTeamHandler = (data) => {
    selectTeam(data)
    setSelectedTab('draft')
  }

  useEffect(() => {
    if (myManager) {
      if (myManager.offense_teams.length >= 2 && myManager.defense_teams.length < 2) {
        setIsOffense(false)
      } else if (myManager.defense_teams.length >= 2 && myManager.offense_teams.length < 2) {
        setIsOffense(true)
      }
    }
  }, [myManager])

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography sx={{ fontSize: 14 }} variant="h6" color={isOffense ? 'initial' : 'primary'}>
            Defense
          </Typography>
          <FormControlLabel
            sx={{ margin: 0, padding: 0 }}
            data-testid="switch-off-def"
            control={
              <Switch
                checked={isOffense}
                onClick={handleSwitchChange}
                sx={{
                  '& .MuiSwitch-thumb': {
                    backgroundColor: isOffense ? 'secondary.main' : 'primary.main',
                  },
                }}
              />
            }
          />
          <Typography sx={{ fontSize: 14 }} variant="h6" color={isOffense ? 'secondary' : 'initial'}>
            Offense
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-around" sx={{ backgroundColor: 'white', paddingLeft: '5px' }}>
          <Button
            sx={{ textTransform: 'none', whiteSpace: 'nowrap', padding: 0, minWidth: '42px' }}
            color={isOffense ? 'secondary' : 'primary'}
            variant={sortType === 'alphabetical' ? 'contained' : 'outlined'}
            onClick={sortAlphabeticallyHandler}
          >
            A-Z
          </Button>
          <Button
            sx={{ textTransform: 'none', whiteSpace: 'nowrap', marginLeft: '5px' }}
            color={isOffense ? 'secondary' : 'primary'}
            variant={sortType === 'projectedPoints' ? 'contained' : 'outlined'}
            onClick={sortProjPointsHandler}
          >
            Proj. Pts
          </Button>
        </Box>
      </Box>

      <Box>
        <Box
          sx={{ overflowY: 'auto', overflowX: 'hidden' }} height="calc(100vh - 395px)"
          marginTop="10px" border="1px solid lightgray" borderRadius="5px">
          {teamsState.map((teamData, key) => (
            <Box
              sx={{ opacity: isTeamAlreadyNominated(teamData.team) ? 0.5 : 1 }}
              borderBottom="1px solid lightgray"
              key={key} display="flex" alignItems="center">
              <TeamPreview
                next_matchup={teamData}
                transaction={{
                  team_to_steal_name: teamData.team.name,
                  kind: teamData.team.is_offense ? 'offense' : 'defense'
                }}
              />
              <IconButton
                disabled={isTeamAlreadyNominated(teamData.team)}
                id={`draftSelectTeamBtn_${teamData.team.id}`}
                onClick={() => selectTeamHandler(teamData)}>
                <AddIcon color='primary' fontSize='small' />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default TeamsTab

import { axiosClient } from '../axios'

export const getMyStealAttempts = async (managerId) => {
  var mySteals = await axiosClient.get(
    `/steals/manager-transactions/${managerId}`
  )
  return mySteals.data
}

export const postMyStealAttempt = async (code) => {
  var response = await axiosClient.post('/steals/steal-attempt', { ...code })
  return response
}

// 3 possible answers for a steal attempt
export const sellTeamAttempt = async (transactionId) => {
  var response = await axiosClient.patch(`/steals/sell-team/${transactionId}`)
  return response
}

export const blockTeamAttempt = async (transactionId) => {
  var response = await axiosClient.patch(`/steals/block-steal/${transactionId}`)
  return response
}

export const filibusterTeamAttempt = async (transactionId) => {
  var response = await axiosClient.patch(`/steals/filibuster/${transactionId}`)
  return response
}

// Cancel a transaction
export const cancelTransaction = async (transactionId) => {
  var response = await axiosClient.patch(
    `/steals/cancel-steal-attempt/${transactionId}`
  )
  return response
}

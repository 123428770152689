import { Box, Typography } from '@mui/material'
import React from 'react'
import { Text } from '../../../../../styles/global'
import './styles.css'
import { DateFormatDay, formatNumberToOneDecimal } from '../../../../../helpers/utils'

export const TeamCard = ({ nflTeam, reverse }) => {
  const quarterRemainingHandler = (quarter) => {
    if (quarter === 'Half' && quarter === 'F') {
      return quarter
    } else {
      return 'Q' + quarter
    }
  }

  const matchPoints = () => {
    if (nflTeam.opponent) {
      return (
        <Typography fontSize={14} noWrap>
          {nflTeam.abbreviation} {nflTeam.points_scored} -{' '}
          {nflTeam.points_against} {nflTeam.opponent}

        </Typography>
      )
    } else {
      return (
        <Typography fontSize={14} noWrap>
          Bye week
        </Typography>
      )
    }
  }

  return (
    <Box
      className={nflTeam?.playing ? 'rainbow' : null}
      component="td"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="10px 5px"
      flexDirection={reverse ? 'row-reverse' : 'row'}
    >
      {nflTeam ? (
        <>
          <Box display="flex" flexDirection="column" alignItems={reverse ? 'end' : 'start'}>
            <Typography whiteSpace='nowrap' marginTop={0} component="span" variant='h6'>
              <span style={{ fontWeight: 'bold' }}>
                {nflTeam.is_offense ? 'Off' : 'Def'}{nflTeam.name}
              </span>
            </Typography>

            <Typography fontSize={12} noWrap>
              {nflTeam.playing ? 'Live Proj' : 'Projected Points'}:{' '}
              {formatNumberToOneDecimal(nflTeam.projected_points)}{' '}
            </Typography>

            {nflTeam.playing ? (
              <Typography fontSize={12} noWrap>{`${quarterRemainingHandler(
                nflTeam.quarter
              )} - ${nflTeam.time_remaining}`}</Typography>
            ) : (
              nflTeam.matchup_date
                ? <Typography fontSize={12} noWrap>
                  {DateFormatDay(nflTeam.matchup_date)} | vs {!nflTeam.is_offense ? 'Off' : 'Def'}-{nflTeam.opponent}
                </Typography>
                :
                matchPoints()
            )}
          </Box>
          <Box
            display="flex"
            justifyContent={reverse ? 'flex-start' : 'flex-end'}
            width="20%"
          >
            <Text variant="h6">{nflTeam.score}</Text>
          </Box>
        </>
      ) : (
        <Box height="105px" />
      )}
    </Box>
  )
}

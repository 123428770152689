import { axiosClient } from '../axios'

export const fillLeague = async (leagueId) => {
  const response = await axiosClient.post(`/leagues/${leagueId}/fill-league`)
  return response.data
}

export const simulatorStatus = async () => {
  const response = await axiosClient.get('/simulator/status')
  return response.data
}

export const startFakeLeague = async (date) => {
  // Iso date is the standard date format yyyy-mm-dd
  let isoDate = new Date(date).toISOString()
  let formattedDate = isoDate.slice(0, 10)
  const response = await axiosClient.post(
    '/simulator/generate-fake-nfl-league',
    {
      start_date: formattedDate,
    }
  )
  return response.data
}

export const scrollToWeek = async (week) => {
  const response = await axiosClient.post(`/simulator/scroll-to-week/${week}`)
  return response.data
}

export const moveToMatchUp = async (matchupId) => {
  const response = await axiosClient.post(
    `/simulator/move-matchup-up/${matchupId}`
  )
  return response.data
}

export const startUpdater = async () => {
  const response = await axiosClient.post('/simulator/start-simulator-mode')
  return response.data
}

export const stopUpdater = async () => {
  const response = await axiosClient.post('/simulator/stop-simulator-mode')
  return response.data
}

import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { MainMatchArea } from './MainMatchArea'
import MatchAccordion from './MatchAccordion'
import { useQuery } from '@tanstack/react-query'

import Subnavbar from './Subnavbar'
import { getLeagueInfo, getLeagueMatchups } from '../../api/services/leagues'
import { MatchupsSkeleton } from '../../components/Skeletons/MatchupsSkeleton'
import { Container } from '@mui/material'
import { NoItemContainer } from '../../components/common/NoItemContainer'
import Navbar from '../../components/Navbar'
import SportsDataBadge from './SportsDataBadge'

export const Matchups = () => {
  const { league, user } = useSelector((state) => state)
  const [week, setWeek] = useState(user.season.current_week)

  const {
    data: matchups,
    isLoading: isMatchupsLoading,
    isError: isMatchupsError,
    refetch,
  } = useQuery(
    [`league_${league.id}_week_${week}`],
    () => getLeagueMatchups(league.id, week),
    {
      retry: 1,
      enabled: league.is_locked
    }
  )

  // In the future, display "start_week" on google login, so I won't need this endpoint.
  const { data: leagueInfo, isLoading: isLeagueInfoLoading } = useQuery(
    [`league_info_${league.id}`],
    () => getLeagueInfo(league.id),
    {
      enabled: league.id > 0 && league.is_locked,
      retry: false,
    }
  )

  useEffect(() => {
    if (leagueInfo?.start_week > user.season.current_week) {
      setWeek(leagueInfo?.start_week)
    }
  }, [leagueInfo, user])

  const newWeekHandler = (newWeek) => {
    setWeek(newWeek)
    refetch()
  }

  if ((isMatchupsLoading || isLeagueInfoLoading) && league.is_locked) {
    return <MatchupsSkeleton />
  }

  // Page when no matchup is founds
  if (isMatchupsError || !league.is_locked) {
    return (
      <>
        <Navbar />
        <Subnavbar
          initialWeek={leagueInfo?.start_week}
          week={week}
          newWeekHandler={newWeekHandler}
        />
        <NoItemContainer text="Playoff matchups are not yet set" hideNavbar />
      </>
    )
  }


  // Make sure to always display first offenses and them defenses
  const sortByKind = (teamData) => {
    var teams = teamData.teams

    const offenses = []
    const defenses = []
    const orderedTeams = []
    for (var i in teams) {
      if (teams[i].is_offense) {
        offenses.push(teams[i])
      } else {
        defenses.push(teams[i])
      }
    }
    orderedTeams.push(...offenses)
    orderedTeams.push(...defenses)

    teamData.teams = orderedTeams

    return teamData
  }

  return (
    <>
      <Navbar />
      <Container maxWidth="md">
        <Subnavbar
          initialWeek={leagueInfo?.start_week}
          week={week}
          newWeekHandler={newWeekHandler}
        />
        <MainMatchArea
          sortByKind={sortByKind}
          firstMatchup={matchups ? matchups[0] : []}
        />
        <MatchAccordion
          sortByKind={sortByKind}
          matchups={matchups ? matchups : []}
        />
        <SportsDataBadge />
      </Container>
    </>
  )
}

import React from '@mui/material'
import { Box, Typography, Grid } from '@mui/material'
import { DateFormatDay, formatNumberToOneDecimal, ordinalSuffixHandler } from '../../../../../../helpers/utils'

const TeamPreview = ({ transaction, next_matchup }) => {
  return (
    <Box
    display="flex" flexDirection="row" justifyContent="space-between" alignItems="start" width="100%" marginTop={0.5} paddingBottom={0.5}>
      <Box margin={1} display="flex" flexDirection="column" alignItems="start" justifyContent="start" minWidth="175px">
        {/* Owned team */}
        <Typography whiteSpace='nowrap' marginTop={0} component="span" variant='h5'>
          <span style={{ fontWeight: 'bold' }}>
            {transaction.kind === 'offense' ? 'Off' : 'Def'}{transaction.team_to_steal_name}
          </span>
          {
            transaction.team_to_steal_value &&
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              {' '}${transaction.team_to_steal_value}
            </span>
          }
        </Typography>
        {/* Next matchup */}
        <Typography marginTop={0} variant='h6' fontSize="0.90rem">
          {transaction.kind !== 'offense' ? 'Off' : 'Def'}-{next_matchup.opponent.abbreviation} | {DateFormatDay(next_matchup.matchup_date)}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" flexDirection="column" justifyContent="space-between">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Owned team score */}
            <Box textAlign="center">
              {transaction.kind === 'offense' ?
                <>
                  <Typography fontSize={15}>
                    {formatNumberToOneDecimal(next_matchup.team.prior_season_ppg)}{' '}
                    ({ordinalSuffixHandler(next_matchup.team.prior_season_ppg_rank)})
                  </Typography>
                  <Typography fontSize={10} fontWeight='bold' color='gray'>
                    {new Date().getFullYear() - 1} PPG
                  </Typography>
                </> :
                <>
                  <Typography fontSize={15}>
                    {formatNumberToOneDecimal(next_matchup.team.prior_season_ppga)}{' '}
                    ({ordinalSuffixHandler(next_matchup.team.prior_season_ppga_rank)})
                  </Typography>
                  <Typography fontSize={10} fontWeight='bold' color='gray'>
                    {new Date().getFullYear() - 1} PPGA
                  </Typography>
                </>
              }
            </Box>
          </Grid>
          <Grid item xs={12}>
            {/* Owned team projections */}
            <Box textAlign="center">
              <Typography fontSize={15}>
                {formatNumberToOneDecimal(next_matchup.team.projected_points)} ({ordinalSuffixHandler(next_matchup.team.projected_points_rank)})
              </Typography>
              <Typography whiteSpace="nowrap" fontSize={10} fontWeight='bold' color='gray'>
                Wk 1 Proj Points
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default TeamPreview

import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
// import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TeamCard } from './TeamCard'
import { NoMatchupCard } from '../../components/NoMatchupCard'

export default function TeamTable({ matchupTeam, reverse }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
        </TableHead>
        <TableBody>
          <TableRow>
            {Array.from({ length: 4 }).map((_, index) => {
              const team = matchupTeam.teams[index]
              return (
                <React.Fragment key={index}>
                  {team ? (
                    <TeamCard reverse={reverse} nflTeam={team} />
                  ) : (
                    <NoMatchupCard reverse={reverse} />
                  )}
                </React.Fragment>
              )
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

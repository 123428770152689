import { Divider, Drawer, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SideMenuCard,
  SideMenuContainer,
  ScrollingData,
  ManagerDrawerTitle
} from '../../styles'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import { nameAbbreviation, timestampForLogs } from '../../../../helpers/utils'
import { setUpdateManagerCash } from '../../../../store/slices/managerSlice'
import { Box } from '@mui/system'
import { Text } from '../../../../styles/global'
import { ManagerCard } from './ManagersDrawer/ManagerCard'
import { toast } from 'react-toastify'

export const SideMenuManagers = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [hasMounted, setHasMounted] = useState(false)

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const { manager, league, draft } = useSelector((state) => state)
  const { managers, draftStatus, auction, onlineManagers } = useSelector(
    (state) => state.draft
  )

  const dispatch = useDispatch()

  // Update manager's cash remaining state (if the draft one changes)
  useEffect(() => {
    // Get my manager
    const myManager = managers.find((manager) => isManagerMe(manager.id))
    // Update value if states are different
    if (myManager.cash_remaining !== manager.cash_remaining) {
      dispatch(setUpdateManagerCash(myManager.cash_remaining))
    }
  }, [managers])

  //   Checks if the current nominating manager it's me
  const isManagerTurn = (managerDraftId) => {
    return managerDraftId === draftStatus.nominating_manager
  }

  const isManagerMe = (managerDraftId) => {
    return managerDraftId === manager.id
  }

  const isManagerBestBidder = (managerDraftId) => {
    return managerDraftId === auction.best_bidder
  }

  const managerIsBestBidder = (id, color = 'success') => {

    if (isManagerBestBidder(id)) {
      return (
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <PaidOutlinedIcon style={{ color }} fontSize='small'
            sx={{ fontSize: '1.05rem' }}
            data-testid="best-bider-manager"
          />
        </Box>

      )
    }
  }

  useEffect(() => {
    if (draftStatus.nominating_manager && managers) {
      const nominating_manager = managers.find(manager => manager.id == draftStatus.nominating_manager)
      console.log(`${timestampForLogs()}: It is ${nominating_manager.name}'s turn to nominate a team.`)

    }
  }, [draftStatus.nominating_manager])


  const managerIsNominator = (id) => {
    if (isManagerTurn(id)) {
      return (
        <Typography
          sx={{
            position: 'absolute',
            left: '10%',
            top: '15%',
          }}
          data-testid="nominating-manager"
        >
          &gt;
        </Typography>
      )
    }
  }

  const textColorHandler = (managerId, color = 'primary.main', defaultColor = 'white') => {
    // Check first if user is inactive
    if (!onlineManagers.includes(managerId)) {
      return '#c0c0c0'
    } else {
      // If is user's turn, change text color
      return isManagerMe(managerId) ? defaultColor : color
    }
  }

  // TODO: Find a clever way to solve this.
  // Context: https://github.com/e14-io/project-captrade/pull/326/files // cap-409
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasMounted(true)
    }, 200)

    return () => clearTimeout(timer)
  }, [])

  const isMyTurn = isManagerTurn(manager.id)

  useEffect(() => {
    if (hasMounted) {
      if (isMyTurn && draftStatus.status === 'in_progress') {
        toast.success('Your turn to nominate!')
      }
    }
  }, [hasMounted, isMyTurn, draftStatus.status])

  return (
    <SideMenuContainer>
      {/* Turn to nominate alert!  */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerToggle}>
        <ManagerDrawerTitle>
          <Text variant="h5">{league?.name}</Text>
        </ManagerDrawerTitle>
        <Divider />

        {draft.managers.map((manager, key) => (
          <ManagerCard
            managerData={manager}
            isManagerTurn={isManagerTurn}
            isManagerBestBidder={isManagerBestBidder}
            isManagerMe={isManagerMe}
            textColorHandler={textColorHandler}
            onlineManagers={onlineManagers}
            key={key}
          />
        ))}
      </Drawer>
      <ScrollingData>
        {managers.map((managerDraft, key) => (
          <SideMenuCard
            onClick={handleDrawerToggle}
            bgcolor={isManagerMe(managerDraft.id) && 'primary.main'}
            key={key}
            sx={{
              textAlign: 'center',
              wordBreak: 'break-word',
              cursor: 'pointer',
              position: 'relative',
            }}
            data-testid={isManagerTurn(managerDraft.id) && 'bidding-manager'}
            id="sideManagerToggle"
          >
            <Box display="flex">
              <Typography
                color={textColorHandler(managerDraft.id)}
                sx={{
                  fontWeight: !onlineManagers.includes(managerDraft.id) && '500',
                }}
                variant="body2"
              >
                {managerIsNominator(managerDraft.id)}
                {nameAbbreviation(managerDraft.name)}
              </Typography>

              {managerIsBestBidder(managerDraft.id, textColorHandler(managerDraft.id, 'green'))}

            </Box>
            <Typography color={textColorHandler(managerDraft.id, 'green')} variant="body2">
              ${managerDraft.cash_remaining}
            </Typography>
          </SideMenuCard>
        ))}
      </ScrollingData>
    </SideMenuContainer>
  )
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import GoogleButton from 'react-google-button'
import LinearProgress from '@mui/material/LinearProgress'

import CustomizedSnackbar from '../common/CustomizedSnackbar'
import { resetManager, setManager } from '../../store/slices/managerSlice'
import { resetLeague, setLeague } from '../../store/slices/leagueSlice'
import { setUser } from '../../store/slices/userSlice'
import { googleLogin } from '../../api/services/google'

import LoginContainer from './LoginContainer'
import LoginImageWrapper from './LoginImageWrapper'
import { resetSteal, setStealData } from '../../store/slices/stealSlice'
import { isTokenValid } from '../../helpers/utils'
import { messages } from '../../messages'
import { resetDraft } from '../../store/slices/draftSlice'
import LoginLinkContainer from './LoginLinkContainer'

function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState(null)

  // If user is already logged with valid token, send to home page.
  useEffect(() => {
    const inviteCode = new URLSearchParams(location.search).get('invite_code')
    if (inviteCode) {
      localStorage.setItem('invite_code', inviteCode)
      !isTokenValid() && signIn()
    }

    if (isTokenValid()) {
      navigate('/league')
    } else {
      cleanRedux()
    }
  }, [])

  const cleanRedux = () => {
    dispatch(setUser(null))
    dispatch(resetLeague())
    dispatch(resetManager())
    dispatch(resetSteal())
    dispatch(resetDraft(resetDraft()))
  }

  function handleCallbackResponse(response_code) {
    setIsLoading(true)

    googleLogin(response_code)
      .then((result) => {
        if (result.data && result.data.user) {
          dispatch(
            setUser({
              ...result.data.user,
              leagues: result.data.leagues ? result.data.leagues : [],
              managers: result.data.managers,
              season: result.data.season,
            })
          )
          // Storing the token and refresh token
          localStorage.setItem('authTokens', JSON.stringify(result.data.jwt))

          if (result.data.leagues?.length > 0) {
            dispatch(setLeague(result.data.leagues[0]))
            dispatch(setManager(result.data.managers[0]))
            dispatch(setStealData(result.data.managers[0]))
          }

          setIsLoading(false)
          navigate('/league')
        }
      })
      .catch((err) => {
        setLoginError(err.message)
      })
      .finally(() => setIsLoading(false))
  }

  const signIn = () => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
    const redirectUri = process.env.REACT_APP_CALLBACK_URL

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ].join(' ')

    const params = {
      response_type: 'code',
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: redirectUri,
      prompt: 'select_account',
      access_type: 'offline',
      scope,
    }

    const urlParams = new URLSearchParams(params).toString()

    window.location = `${googleAuthUrl}?${urlParams}`
  }

  useEffect(() => {
    const params = new URL(document.location).searchParams
    if (params.get('code')) {
      let code = params.get('code')
      window.history.replaceState({}, document.title, '/' + '')
      handleCallbackResponse(code)
    }
  }, [])

  function isInstagramWebView() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    return (userAgent.indexOf('Instagram') > -1)
  }

  const isAndroid = /Android/i.test(navigator.userAgent)

  return (
    <LoginContainer className="d-flex justify-content-center align-items-center mt-5 ms-auto me-auto p-4">
      <CustomizedSnackbar isError={!!loginError} errorMessage={loginError} />
      <LoginImageWrapper>
        <img
          src="/assets/images/captrade-logo.png"
          style={{ width: '220px' }}
        />
      </LoginImageWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <h1
          style={{ fontWeight: 'bold', color: '#023367', marginBottom: '25px' }}
        >
          {messages[2].welcomeMessage}
        </h1>
        <div style={{ margin: '0 1em' }}>
          {isLoading ? <LinearProgress /> : <GoogleButton id='googleSignInBtn' disabled={isInstagramWebView()} onClick={signIn} />}{' '}
        </div>

        {/* Redirection when it's open from Instagram */}

        {isInstagramWebView() && (
          <LoginLinkContainer>
            <a
              href={
                isAndroid
                  ? `intent:${process.env.REACT_APP_CALLBACK_URL}#Intent;end`
                  : `googlechrome://${process.env.REACT_APP_CALLBACK_URL.replace(/^https?:\/\//, '')}`
              }
              target="_blank"
              rel="noreferrer"
            >
              Open in Browser
            </a>
          </LoginLinkContainer>
        )}
      </div>
    </LoginContainer>
  )
}

export default Login

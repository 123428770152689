import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { messages } from '../../../../messages'

export const NoTeamCard = () => {
  return (
    <Box margin="5px 0" sx={{ cursor: 'pointer' }}>
      <Paper>
        <Box display="flex" alignItems="center" color="gray">
          <Box
            component="img"
            width="83px"
            padding="45px 10px"
            src="/assets/images/logo-bw.png"
          />
          <Typography>{messages[15].emptyTeamSlot}</Typography>
        </Box>
      </Paper>
    </Box>
  )
}

import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Landing from '../components/landing/Landing'
import { Admin } from '../pages/admin'
import { Draft } from '../pages/draft'
import { League } from '../pages/league'
import { LeagueDetail } from '../pages/league/LeagueDetail'
import { Matchups } from '../pages/matchups'
import { MyTeam } from '../pages/myTeam'
import { PageNotFound } from '../pages/PageNotFound'
import { ProtectedRoutes } from './ProtectedRoutes'
import { Transactions } from '../pages/transactions'

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/*" element={<PageNotFound />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/admin" element={<Admin />} />
          <Route path="/home" element={<League />} />
          <Route path="/matchups" element={<Matchups />} />
          <Route path="/my-team" element={<MyTeam />} />
          <Route path="/league" element={<LeagueDetail />} />
          <Route path="/draft" element={<Draft />} />
          <Route path="/transactions" element={<Transactions />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

import moment from 'moment'
import jwt_decode from 'jwt-decode'
import { BUILD_DATE } from '../config'

export function randomN(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function DateFormat(date) {
  return moment(date).format('MMM DD, H:mm')
}

export function DateFormatDay(date) {
  return moment(date).format('ddd h:mm A')
}

// Only shows managers 3 first letters
export const nameAbbreviation = (fullname) => {
  const firstName = fullname.split(' ')[0]
  const abbreviation = firstName.slice(0, 3)
  return abbreviation.toUpperCase()
}

// harcoded until the bakcend gives me the logos
export const teamPics = {
  ARI: 'https://upload.wikimedia.org/wikipedia/en/7/72/Arizona_Cardinals_logo.svg',
  ATL: 'https://upload.wikimedia.org/wikipedia/en/c/c5/Atlanta_Falcons_logo.svg',
  BAL: 'https://upload.wikimedia.org/wikipedia/en/1/16/Baltimore_Ravens_logo.svg',
  BUF: 'https://upload.wikimedia.org/wikipedia/en/7/77/Buffalo_Bills_logo.svg',
  CAR: 'https://upload.wikimedia.org/wikipedia/en/1/1c/Carolina_Panthers_logo.svg',
  CHI: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Chicago_Bears_logo.svg',
  CIN: 'https://upload.wikimedia.org/wikipedia/commons/8/81/Cincinnati_Bengals_logo.svg',
  CLE: 'https://upload.wikimedia.org/wikipedia/en/d/d9/Cleveland_Browns_logo.svg',
  DAL: 'https://upload.wikimedia.org/wikipedia/commons/1/15/Dallas_Cowboys.svg',
  DEN: 'https://upload.wikimedia.org/wikipedia/en/4/44/Denver_Broncos_logo.svg',
  DET: 'https://upload.wikimedia.org/wikipedia/en/7/71/Detroit_Lions_logo.svg',
  GB: 'https://upload.wikimedia.org/wikipedia/commons/5/50/Green_Bay_Packers_logo.svg',
  HOU: 'https://upload.wikimedia.org/wikipedia/en/2/28/Houston_Texans_logo.svg',
  IND: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Indianapolis_Colts_logo.svg',
  JAX: 'https://upload.wikimedia.org/wikipedia/en/7/74/Jacksonville_Jaguars_logo.svg',
  KC: 'https://upload.wikimedia.org/wikipedia/en/e/e1/Kansas_City_Chiefs_logo.svg',
  LAC: 'https://upload.wikimedia.org/wikipedia/commons/a/a6/Los_Angeles_Chargers_logo.svg',
  LAR: 'https://upload.wikimedia.org/wikipedia/en/8/8a/Los_Angeles_Rams_logo.svg',
  LV: 'https://upload.wikimedia.org/wikipedia/en/4/48/Las_Vegas_Raiders_logo.svg',
  MIA: 'https://upload.wikimedia.org/wikipedia/en/3/37/Miami_Dolphins_logo.svg',
  MIN: 'https://upload.wikimedia.org/wikipedia/en/4/48/Minnesota_Vikings_logo.svg',
  NE: 'https://upload.wikimedia.org/wikipedia/en/b/b9/New_England_Patriots_logo.svg',
  NO: 'https://upload.wikimedia.org/wikipedia/commons/5/50/New_Orleans_Saints_logo.svg',
  NYG: 'https://upload.wikimedia.org/wikipedia/commons/6/60/New_York_Giants_logo.svg',
  NYJ: 'https://upload.wikimedia.org/wikipedia/en/6/6b/New_York_Jets_logo.svg',
  PHI: 'https://upload.wikimedia.org/wikipedia/en/8/8e/Philadelphia_Eagles_logo.svg',
  PIT: 'https://upload.wikimedia.org/wikipedia/commons/d/de/Pittsburgh_Steelers_logo.svg',
  SEA: 'https://upload.wikimedia.org/wikipedia/en/8/8e/Seattle_Seahawks_logo.svg',
  SF: 'https://upload.wikimedia.org/wikipedia/commons/3/3a/San_Francisco_49ers_logo.svg',
  TB: 'https://upload.wikimedia.org/wikipedia/en/a/a2/Tampa_Bay_Buccaneers_logo.svg',
  TEN: 'https://upload.wikimedia.org/wikipedia/en/c/c1/Tennessee_Titans_logo.svg',
  WAS: 'https://upload.wikimedia.org/wikipedia/commons/0/0c/Washington_Commanders_logo.svg',
}

export const allowDashboardAccess = [
  'lnmarquez19@gmail.com',
  'joeypchapin@gmail.com',
  'leandro.marquez@e14.io',
  'pablo.bangueses@e14.io',
  'christian@e14.io',
]

export const rankColorHandler = (position, reverse = false) => {
  const rank = Math.trunc(position)

  switch (true) {
    case rank <= 11:
      return reverse ? 'red' : 'green'
    case rank > 12 && rank <= 22:
      return 'black'
    case rank > 22:
      return reverse ? 'green' : 'red'
    default:
      return null
  }
}

export const ordinalSuffixHandler = (rank) => {
  let ord = 'th'

  switch (true) {
    case rank % 10 === 1 && rank % 100 !== 11:
      ord = 'st'
      break
    case rank % 10 === 2 && rank % 100 !== 12:
      ord = 'nd'
      break
    case rank % 10 === 3 && rank % 100 !== 13:
      ord = 'rd'
      break
  }

  return `${rank}${ord}`
}

export const isManagerCommissioner = (managerId, auctioneerId) => {
  if (managerId === auctioneerId) {
    return true
  }
  return false
}

export const isTokenValid = () => {
  const tokens = localStorage.getItem('authTokens')
  if (tokens) {
    let decodedToken = jwt_decode(tokens)

    let currentData = new Date()
    if (decodedToken.exp * 1000 >= currentData.getTime()) {
      return true
    }
  }

  return false
}

export const changeCurrentLeague = (user, selectedLeagueId) => {
  const selectedLeague = user.leagues.find(
    (league) => league.id === selectedLeagueId
  )

  const selectedManager = user.managers.find(
    (manager) => manager.league_id === selectedLeagueId
  )
  const res = { selectedLeague, selectedManager }
  return res
}

// Checks if a team was already chosen.
export const isTeamAlreadyNominated = (teamInfo) => {
  return teamInfo.owner_id !== null
}

export const formatCurrency = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
}

export const formatNumberToOneDecimal = (number) => {
  return parseFloat(number)?.toFixed(1)
}

export const timestampForLogs = () => {
  const time = new Date().toLocaleTimeString('en-US', { hour12: false })
  const milliseconds = String(new Date().getMilliseconds()).padStart(3, '0')

  return `${time}:${milliseconds}`
}

export const checkBuildDateAndLogout = () => {
  const storedBuildDate = localStorage.getItem('buildDate')
  if (storedBuildDate !== BUILD_DATE) {
    localStorage.setItem('buildDate', BUILD_DATE)
    localStorage.removeItem('authTokens')
    window.location.replace('/')
  }
}

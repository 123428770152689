import React from 'react'
import { Box, Typography } from '@mui/material'
//import { teamPics } from '../../../../../../helpers/utils'

export const DraftRosterItem = ({ myTeam }) => {
  if (!myTeam) {
    return (
      <Box
        sx={{
          background: 'lightgray',
          width: '25px',
          height: '25px',
          borderRadius: ' 50%',
          margin: '10px 12px',
        }}
      />
    )
  }
  return (
    <Box display="flex" flexDirection="column" alignItems="center">

      {/* <Box
        padding="0 10px"
        component="img"
        width="50px"
        height="30px"
        src={teamPics[myTeam.abbreviation]}
      /> */}

      <Typography whiteSpace="nowrap" fontSize='14px'>{myTeam.is_offense ? 'Off' : 'Def'}-{myTeam.abbreviation}</Typography>
      <Typography fontSize='14px' color="green">${myTeam.market_value}</Typography>
    </Box>
  )
}

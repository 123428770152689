import * as React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'


dayjs.extend(utc)

export default function DatePickerInput({ fakeLeagueStartDate, setFakeLeagueStartDate, simulator }) {

  const disableNonMatchingWeekdays = (date) => {
    return dayjs(date).day() !== 4 // Only allows Thursdays
  }

  const setStartDate = (newValue) => {
    const dateInUTC = dayjs(newValue).utc().startOf('day')
    setFakeLeagueStartDate(dateInUTC.toString())
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={simulator?.season ? 'Regenerate Fake NFL starting on' : 'Generate Fake NFL starting on'}
        value={dayjs(fakeLeagueStartDate)}
        onChange={(newValue) => setStartDate(newValue)}
        disabled={simulator?.enabled}
        shouldDisableDate={disableNonMatchingWeekdays}
      />
    </LocalizationProvider>
  )
}

import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'language',
  initialState: null,
  reducers: {
    setUser: (state, action) => {
      return action.payload
    },
    updateUserLeagues: (state, action) => {
      state.leagues = action.payload
    },
    updateUserSeasonCurrentWeek: (state, action) => {
      state.season.current_week = parseInt(action.payload)
    },
    updateSeason: (state, action) => {
      state.season = action.payload
    },
    pushUserLeagueAndManager: (state, action) => {
      const { league, manager } = action.payload

      const leagueIndex = state.leagues.findIndex(l => l.id === league.id)
      if (leagueIndex !== -1) {
        state.leagues[leagueIndex] = league
      } else {
        state.leagues.push(league)
      }

      state.managers = state.managers.filter(m => m.league_id !== league.id)
      state.managers.push(manager)
    },
    setUserTeams: (state, action) => {
      state.teams = action.payload
    },
    setLockSingleUserLeague: (state, action) => {
      state.leagues.forEach((league) => {
        league.id === action.payload && (league.is_locked = true)
      })
    },
  },
})

export const {
  setUser,
  setUserTeams,
  setLockSingleUserLeague,
  updateUserLeagues,
  updateUserSeasonCurrentWeek,
  pushUserLeagueAndManager,
  updateSeason,
} = userSlice.actions

export default userSlice.reducer

import { Box, LinearProgress, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef } from 'react'
import MyTeamsRoster from '../../MyTeamsRoster'
import {
  getAvailableDefenses,
  getAvailableOffenses,
} from '../../../../api/services/team'
import { messages } from '../../../../messages'
import { useSelector } from 'react-redux'

export default function StealAreaRoster({ myTeamPageRefetch }) {
  const { id: managerId } = useSelector((state) => state.manager)
  const { type } = useSelector((state) => state.steal)
  const myRef = useRef(null)

  useEffect(() => {
    if (myRef.current != null) {
      const offset = 200

      const topPosition = myRef.current.getBoundingClientRect().top + window.scrollY - offset

      window.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      })
    }
  }, [type])

  const { isLoading: isLoadingOff, data: teamsOff } = useQuery(
    ['availableOffenses'],
    () => getAvailableOffenses(managerId)
  )

  const { isLoading: isLoadingDef, data: teamsDef } = useQuery(
    ['availableDefenses'],
    () => getAvailableDefenses(managerId)
  )

  const rowHandler = () => {
    if (isLoadingOff || isLoadingDef) {
      return <LinearProgress />
    }
    return (
      <>
        <MyTeamsRoster
          teams={type === 'offenses'
            ? teamsOff.claimed_offenses
            : teamsDef.claimed_defenses
          }
          isStealable
          type="roster"
          myTeamPageRefetch={myTeamPageRefetch}
          hideEmpty
        />

        <MyTeamsRoster
          teams={type === 'offenses'
            ? teamsOff.unowned_offenses
            : teamsDef.unowned_defenses
          }
          isStealable
          type="roster"
          myTeamPageRefetch={myTeamPageRefetch}
        />
      </>
    )
  }

  return (
    <Box marginTop="40px" ref={myRef}>
      <Box display="flex" justifyContent="" borderBottom="1px solid lightgray">
        <Typography
          variant="h5" sx={{ fontWeight: 'bold' }} fontWeight="bold">
          {messages[13].availableTeams}
        </Typography>
      </Box>
      {rowHandler()}
    </Box>
  )
}

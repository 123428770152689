import styled from '@emotion/styled'

export default styled.div`
  display: flex;
  justify-content: center;
  min-width: 280px;

  @media (min-width: 900px) {
    border-right: 2px solid #d9d9d9;
  }
`

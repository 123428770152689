import { Box, Paper, Typography } from '@mui/material'
import React from 'react'

export const NoMatchupCard = ({ reverse }) => {

  return (
    <Box margin="2px 0" sx={{ cursor: 'pointer' }}>
      <Paper elevation={0}>
        <Box
          display="flex"
          alignItems="center"
          padding={{ xs: '27.5px 10px', sm: '29px 10px' }}
          justifyContent={reverse ? 'end' : 'start'}
          color="gray"
        >
          <Box
            component="img"
            width="60px"
            src="/assets/images/logo-bw.png"
          />
          <Typography>Empty slot</Typography>
        </Box>
      </Paper>
    </Box>
  )
}

import * as React from 'react'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useState, useEffect } from 'react'
import { messages } from '../../../messages'
import { useRef } from 'react'

export default function CustomizedSnackbar({
  isError,
  isSuccess,
  errorMessage,
  successMessage,
  onTopOfBar,
  onBottomOfBar,
  duration
}) {
  const [open, setOpen] = useState(false)
  const hasMounted = useRef(false)

  useEffect(() => {
    // To prevent the snackbar from pops up when the user reconnect
    if (!hasMounted.current) {
      hasMounted.current = true
      return
    }

    if (isError || isSuccess) {
      setOpen(true)
    }
  }, [isError, isSuccess])

  const handleClose = () => {
    setOpen(false)
  }

  const errorMessageHandler = () => {
    return errorMessage ? errorMessage : messages[0].defaultError
  }

  const successMessageHandler = () => {
    return successMessage ? successMessage : messages[0].defaultSuccess
  }

  let position = { vertical: 'top', horizontal: 'center' }
  let style = {}

  if (onTopOfBar) {
    position = { vertical: 'bottom', horizontal: 'center' }
    style = { marginBottom: '56px' }
  }

  if (onBottomOfBar) {
    position = { vertical: 'top', horizontal: 'center' }
    style = { marginTop: '48px' }
  }

  return (
    <Stack spacing={2} sx={{ width: '100%', zIndex: 99 }}>
      <Snackbar
        open={open}
        autoHideDuration={duration || 1000}
        onClose={handleClose}
        anchorOrigin={position}
        style={style}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={isError ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {isError ? errorMessageHandler() : successMessageHandler()}
        </MuiAlert>
      </Snackbar>
    </Stack>
  )
}

import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { LinearProgress } from '@mui/material'
import { messages } from '../../../messages'

export default function AlertDialog({
  open,
  setOpen,
  handleDelete,
  isLoading,
}) {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messages[11].confirmMessage}
          </DialogContentText>
        </DialogContent>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <DialogActions>
            <Button id='alertDialogClose' onClick={handleClose}>Cancel</Button>
            <Button id='alertDialogConfirm' onClick={handleDelete} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

import axios from 'axios'
import { isTokenValid } from '../helpers/utils'
// import { refreshToken } from './services/auth'

const URL = process.env.REACT_APP_API_URL

//  Get token and refresh token

export const axiosClient = axios.create({
  baseURL: URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// This will be called before the request is finished.
axiosClient.interceptors.request.use(async (req) => {
  let authTokens = JSON.parse(localStorage.getItem('authTokens'))
  try {
    // This chunk of code is to avoid ..

    req.headers.Authorization = `Bearer ${authTokens?.access}`

    // Check if token is not valid anymore (and if we have any).
    if (!isTokenValid() && authTokens) {
      // Generate the new token
      const refreshTokenString = authTokens.refresh
      axios
        .post(`${URL}/auth/refresh_token`, {
          refresh: refreshTokenString,
        })
        .then((res) => {
          console.log('Token updated!')
          authTokens.access = res.data.access

          localStorage.setItem('authTokens', JSON.stringify(authTokens))
        })
        .catch(() => {
          localStorage.removeItem('authTokens')
          location.reload()
        })

      // Update the current one
    }

    return req
  } catch (err) {
    console.log(err)
  }
})

// axiosClient.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { status } = error.response
//     if (status === 401) {
//       window.location.replace('/')
//     }
//     return Promise.reject(error)
//   }
// )

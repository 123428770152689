import { Skeleton } from '@mui/material'
import React from 'react'
import { StyledSkeleton } from './style'

export const DraftSkeleton = () => {
  return (
    <>
      <StyledSkeleton variant="rectangular" />
      <StyledSkeleton variant="rectangular" mirror />
      <Skeleton
        variant="rectangular"
        sx={{ margin: '0 100px', height: '300px' }}
      />
      <Skeleton
        variant="rectangular"
        sx={{ margin: '50px 100px', height: '300px' }}
      />
      <Skeleton
        variant="rectangular"
        sx={{ margin: '20px 100px', height: '100px' }}
      />
    </>
  )
}

import React from 'react'
import Navbar from '../../components/Navbar'
import { MyLeagues } from './MyLeagues'

export const League = () => {
  return (
    <>
      <Navbar />
      <MyLeagues />
    </>
  )
}

import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import moment from 'moment'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined'

export default function BasicSelect({
  label,
  options,
  data,
  onChange,
  isMatchup,
  fontSize = null
}) {
  const iconHandler = (option) => {
    if (isMatchup && option.is_over) {
      return <DoDisturbAltOutlinedIcon sx={{ marginLeft: '5px' }} />
    } else if (isMatchup && !option.is_over) {
      return null
    } else if (!isMatchup && option.is_locked) {
      return <LockOutlinedIcon sx={{ marginLeft: '5px' }} />
    } else if (!isMatchup && !option.is_locked) {
      return <LockOpenOutlinedIcon sx={{ marginLeft: '5px' }} />
    }
  }

  const selectStyle = fontSize ? { fontSize } : {}

  return (
    <Box sx={{ width: 222 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={data}
          label={label}
          onChange={(e) => onChange(e.target.value)}
          defaultValue={''}
          SelectDisplayProps={{
            style: selectStyle
          }}
        >
          {options?.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              {isMatchup
                ? `${option.home_team_name} vs ${
                    option.away_team_name
                  } - ${moment(option.date).format('ddd h:mm A')}`
                : option.name}
              {/* Displays the icon */}
              {iconHandler(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

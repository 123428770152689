import React from 'react'
import StealAreaRoster from './StealAreaRoster'
import { useSelector } from 'react-redux'

export const RosterMoves = ({ myTeamPageRefetch, leagueUpdatedData }) => {
  const { type } = useSelector((state) => state.steal)

  if (
    leagueUpdatedData?.draft_status !== 'finished' &&
    leagueUpdatedData?.draft_status !== 'not_available'
  ) {
    return null
  }
  return (
    <>
      {type !== null ? (
        <StealAreaRoster myTeamPageRefetch={myTeamPageRefetch}/>
      ) : (
        null
      )}
    </>
  )
}

import * as React from 'react'
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import { Box } from '@mui/system'
import { Text } from '../../../../styles/global'
import { getLeagueInfo } from '../../../../api/services/leagues'
import { useQuery } from '@tanstack/react-query'
import { TeamTableCell } from './TeamTableCell'
import { Skeletons } from '../../../../components/Skeletons'
import ManageLeagueDialog from './ManageLeagueDialog'
import DeleteManagerDialog from './DeleteManagerDialog'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useSelector } from 'react-redux'
import { ResponsiveLinkButton } from '../../../../components/common/ResponsiveLinkButton'

export default function LeagueTable({ leagueData }) {
  const { managers } = useSelector((state) => state.user)

  const { data, isLoading, refetch } = useQuery(
    [`my_league_${leagueData.id}`],
    () => getLeagueInfo(leagueData.id),
    {
      enabled: leagueData.id > 0,
      retry: false,
    }
  )

  if (!leagueData.id) {
    return null
  }

  if (isLoading) {
    return <Skeletons type="league" />
  }

  const isManagerTeamHandler = (tableTeamId) => {
    return managers.some((manager) => manager.id === tableTeamId)
  }

  //   Check if the current league is one of the leagues which the user is commissioner.
  const isUserLeagueCommisioner = () => {
    const commissioners = managers.filter((manager) => manager.is_commissioner)
    const { id: currentLeagueId } = leagueData

    for (var i in commissioners) {
      if (commissioners[i].league_id === currentLeagueId) {
        return true
      }
    }

    return false
  }

  let disabled = false
  let disabledReason = ''

  if (data?.is_mock) {
    if (data.managers.length % 2 !== 0) {
      disabled = true
      disabledReason = 'An even number of managers is required to start the draft.'
    } else if (data.managers.length < 4) {
      disabled = true
      disabledReason = 'At least 4 managers are required to start the draft.'
    }
  }

  return (
    <Box padding="10px 0 30px 0">
      <Box display="flex" justifyContent="end" margin="0 0 10px 0">
        {/* Only show on desktop*/}
        <Hidden mdDown>
          <ResponsiveLinkButton
            url="/draft"
            text="Enter draft room"
            leagueUpdatedData={data}
            leagueUpdatedDataLoading={isLoading}
            leagueId={leagueData.id}
            disabled={disabled}
            disabledReason={disabledReason}
          />
        </Hidden>
        {isUserLeagueCommisioner() &&
          <ManageLeagueDialog leagueData={{ ...data, password: leagueData.password, has_ended: leagueData.has_ended }} refetchLeague={refetch} />
        }
      </Box>
      {/* Only show on mobile */}
      <Hidden mdUp>
        <ResponsiveLinkButton
          url="/draft"
          text="Enter draft room"
          leagueUpdatedData={data}
          leagueUpdatedDataLoading={isLoading}
          leagueId={leagueData.id}
          disabled={disabled}
          disabledReason={disabledReason}
        />
      </Hidden>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Pos</TableCell>
              <TableCell align="center">Standings</TableCell>

              <Hidden mdDown>
                <TableCell align="center">Cash</TableCell>
                <TableCell align="center">W-L-T</TableCell>
              </Hidden>

              <TableCell align="center"> Offenses</TableCell>
              <TableCell align="center"> Defenses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.managers.map((row, i) => (
              <TableRow
                key={row.name}
                style={{
                  backgroundColor:
                    isManagerTeamHandler(row.id) && 'rgb(128 128 128 / 9%)',
                }}
              >
                <TableCell align="center" style={{ width: '5%' }}>
                  <div>{i + 1}</div>
                </TableCell>
                <Hidden mdUp>
                  <TableCell
                    align="center"
                    style={{ width: '30%', padding: '5px' }}
                  >
                    <Text display="block" variant="caption">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'end'
                        }}
                      >
                        {row.name}{' '}
                        {row.is_commissioner && isUserLeagueCommisioner() ? (
                          <CheckCircleIcon
                            sx={{ width: '15px', color: 'primary.main' }}
                          />
                        ) :
                          !leagueData.is_locked && isUserLeagueCommisioner() &&
                          <DeleteManagerDialog manager={row} refetchLeague={refetch} />
                        }
                      </div>
                    </Text>

                    <Text display="block" variant="caption">
                      {`${row.wins} - ${row.losses} - ${row.ties}`}
                    </Text>
                    <Text variant="caption" hasprice component="span">
                      Cash: <span>${row.cash_remaining}</span>
                    </Text>
                  </TableCell>
                </Hidden>
                <Hidden mdDown>
                  <TableCell
                    align="center" style={{ width: '30%' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'end'
                      }}
                    >
                      {row.name}
                      {row.is_commissioner ? (
                        <CheckCircleIcon
                          sx={{
                            width: '15px',
                            color: 'primary.main',
                            marginLeft: '5px',
                          }}
                        />
                      ) :
                        !leagueData.is_locked && isUserLeagueCommisioner() &&
                        <DeleteManagerDialog manager={row} refetchLeague={refetch} />
                      }
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: {
                        sx: '30%',
                        md: '10%',
                      },
                    }}
                  >
                    <span style={{ color: 'green' }}>
                      ${row.cash_remaining}
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: '20%',
                    }}
                  >
                    {`${row.wins} - ${row.losses} - ${row.ties}`}
                  </TableCell>
                </Hidden>

                <TeamTableCell
                  firstTeam={row.offense_teams[0]}
                  secondTeam={row.offense_teams[1]}
                  pointsAgainst={row.points_against}
                  pointsScored={row.points_scored}
                />
                <TeamTableCell
                  firstTeam={row.defense_teams[0]}
                  secondTeam={row.defense_teams[1]}
                  pointsAgainst={row.points_against}
                  pointsScored={row.points_scored}
                  isDefense
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box >
  )
}
